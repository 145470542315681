// vista para mostrar las solicitudes pendientes para los usuario que no sean de contabilidad
// y para los supervisores y directores para que puedan aprobar



import { useEffect, useState } from "react";


// components importation
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { ButtonSearch } from "../../components/buttonsComponents/ButtonSearch.jsx";
import { SearchConsumpRequeComp } from "../../components/administration-compon/SearchConsumpRequeComp.jsx";


// react router import
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";

// urls import
import { urls } from "../../api-urls/api-urls.js";
import { formatCurrency, getTheToken, renderDate } from "../../global-functions/globalFunctions.js";


export const SolConsumpViewPrin = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();

  // state definition
  const [dataServer, setDataServer] = useState([]); // estado para cargar la data del servidor
  const [controller, setController] = useState(null);
  const [state, setState] = useState({ isLoading: false, msg: "", modalSearch: false, msg: "" });

  // const [stateForm, setStateForm] = useState({ etapa: "" });


  // useSearchParams
  const [params, setParams] = useSearchParams();




  // destructuring data server
  // const { pendings } = dataServer && dataServer;


  // funcion para abrir el modal de buscar
  const onOpenModalSearch = () => {
    setState((val) => ({ ...val, modalSearch: !state.modalSearch }));
  };


  // funcion para recibir las notificaciones del componente de busqueda
  const onHandleNotify = (msg = "") => {
    setState((val) => ({ ...val, msg: msg }));
  };


  // funcion para setear el estado de la data del servidor al filtrarla
  const onChangeDataState = (newArr = []) => {
    setDataServer(newArr);
  };


  // funcion para realizar el fetch de datos
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);

    const END_POINT = `${urls.autorizaciones}?language=${language}&action=4&codSol=${params.get("cod_sol") ?? ""}&nom_empl=${params.get("nom_empl") ?? ""}&consu_final=${params.get("consu_fin") ?? ""}&etapa=${params.get("etapa") ?? ""}&nom_empr=${params.get("empre") ?? ""}&gerencia=${params.get("geren") ?? ""}&filt=${params.get("filt") ?? "0"}`;

    try {
      setState((val) => ({ ...val, isLoading: true }));

      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data?.pendings);
        setState((val) => ({ ...val, msg: "" }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setState((val) => ({ ...val, msg: data?.msg }));
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para solicitar la data al servidor
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [language]);





  return (
    <div className="container display-data">
      {
        // loading component
        state.isLoading && (<LoadingComp />)
      }
      <div className="row-flex m-y-small border-bottom j-content-space-b">
        <div className="col-3">
          <h3>Pendientes</h3>
        </div>
        <div className="col-8">
          <button type="button" className="btn-success" onClick={() => navigate("/administration/create-consumption-request")}>Nuevo</button>
        </div>
      </div>

      {
        state.msg.trim() && (
          <div className="fade-animation">
            <h4 className="required">{state.msg}</h4>
          </div>
        )
      }

      {
        dataServer && dataServer.length > 0 && (
          <div className="table-container-mobile">
            <table className="table">
              <thead className="table-head">
                <tr>
                  <th>Fecha</th>
                  <th>Solicitud</th>
                  <th>Consumidor final</th>
                  <th>Monto</th>
                  <th>Solicitante</th>
                  <th>Etapa</th>
                  <th>Division</th>
                  <th>Gerencia</th>
                  <th>Comentario</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {
                  dataServer && dataServer.map((pend) => (
                    <tr key={pend.CodSolicitud} className={pend.Update ? "alert-smooth" : ""}>
                      <td>{renderDate(pend.FecIngreso, language)}</td>
                      <td className="txt-center">
                        <Link className="link-tag" to={`/administration/consumption-request-detail/${pend.CodSolicitud}`}>{pend.CodSolicitud}</Link>
                      </td>
                      <td>{pend.ConsuFinal}</td>
                      <td>
                        <strong className="active-class">{formatCurrency(pend.MontoConsumo, "USD")}</strong></td>
                      <td>{pend.NomEmpleado}</td>
                      <td>{pend.NomEtapa}</td>
                      <td>{pend.NomDivision}</td>
                      <td>{pend.NomGeren}</td>
                      <td>{pend.Comentario}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        )
      }

      <ButtonSearch onOpenFilter={onOpenModalSearch} />

      {
        // modal para los filtros
        state.modalSearch && (
          <SearchConsumpRequeComp onClose={onOpenModalSearch} params={params} setParams={setParams} token={getTheToken().token} lang={language} newStateData={onChangeDataState} onNotify={onHandleNotify} />
        )
      }
    </div>
  );
};