// componente para las tarjetas

import { Link, useNavigate } from "react-router-dom";


import { renderTempCard, renderDate } from "../../global-functions/globalFunctions.js";



export const CardInformation = ({ ArrData = [], lang = "es" }) => {

  const navigate = useNavigate();

  return (
    <>
      {
        ArrData && ArrData.map((data) => (
          <div key={data.KeyId} className={`row-flex card-shadow m-y-small ${renderTempCard(data.Prioridad)}`}>
            <div className="col-5">
              <strong>Id Servicio</strong>
            </div>
            <div className="col-4">
              <Link className="link-tag" to={`/service/service-call/${data.CodServicio}`}>{data.CodServicio}</Link>
            </div>
            <div className="col-5">
              <strong>Solicitud</strong>
            </div>
            <div className="col-4">
              <strong>{data.CodSolRepuesto}</strong>
            </div>
            <div className="col-5">
              <strong>Cliente</strong>
            </div>
            <div className="col-80">
              <Link className="link-tag">{data.NomCliente}</Link>
            </div>
            <div className="col-5">
              <strong>Equipo</strong>
            </div>
            <div className="col-80">
              <Link className="link-tag">{data.Equipo}</Link>
            </div>
            <div className="col-5">
              <strong>Solicitado por</strong>
            </div>
            <div className="col-80">
              <span className="txt-muted">{data.EmpSolicita} - {renderDate(data.Fecha, lang)}</span>
            </div>
            <div className="col-5">
              <strong>Zona</strong>
            </div>
            <div className="col-3">
              <span className="txt-muted">{data.Zona}</span>
            </div>
            <div className="col-3 row-flex flex-end">
              <button type="button" className="btn-review" onClick={() => navigate(data.URL)}>Ver</button>
            </div>
          </div>
        ))
      }
    </>
  );
};