// componente para las reservaciones

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { urls } from "../../../api-urls/api-urls.js";

import { ColorPicker } from "../../../global-functions/globalFunctions.js";


export const useFetchReserva = (END_POINT = "", token = "", lang = "") => {

  // state definition
  const [currentDate, setCurrentDate] = useState(new Date());
  const [form, setForm] = useState({ showModal: false, title: "", date: "", dateUntil: "", timeFrom: "", timeUntil: "", location: "5", invitado: "", description: "", invitados: [], evento: null, bgColor: "", nomSala: "" }); // location 5 salon de conferencias principal del segundo piso
  const [dataState, setDataState] = useState({}); // estado para colocar la data
  const [controller, setController] = useState(null); // estado para cancelar la data del servidor
  const [state, setState] = useState({ isLoading: false, sending: false, reload: false });
  const [arrColor, setArrColor] = useState(ColorPicker);
  const StartHour = 7;
  const EndHour = 19;
  const DaysOfWeek = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'];

  const { reserva, rooms } = dataState && dataState;

  const navigate = useNavigate();

  // funcion para recargar la data
  const handleReloadData = () => {
    setState((val) => ({ ...val, reload: !state.reload }));
  };


  // funcion para cambiar valores multiples del formulario
  const handleChangeMultiForm = (newVal = {}) => {
    setForm((val) => ({ ...val, ...newVal }));
  };


  // funcion para cambiar el estado de enviar data al servidor
  const handleSendDataServ = (newVal = false) => {
    setState((val) => ({ ...val, sending: newVal }));
  };


  // funcion para setear el loading
  const setLoadingData = () => {
    setState((val) => ({ ...val, isLoading: !state.isLoading }));
  };

  // funcion para eliminar un empleado
  const handleDeleteInvit = (emp = 0) => {
    setForm((val) => ({ ...val, invitados: val.invitados.filter((inv) => inv !== emp) }));
  };


  // funcion para cambiar los valores del formulario
  const handleChangeFormVal = (name = "", newVal = "", addInv = false) => {

    if (name === "showModal" && newVal === false) {
      setForm((val) => ({ ...val, title: "", date: "", dateUntil: "", timeFrom: "", timeUntil: "", invitados: [], description: "", evento: null, bgColor: "" }));
      // console.log("Entro acaaaa")
    }

    if (!addInv) {
      setForm((val) => ({ ...val, [name]: newVal }));
    } else {
      setForm((val) => ({ ...val, invitados: [...val.invitados, Number(newVal)] }));
    }

    if (name === "location") {
      const FindSala = rooms && rooms.find((ro) => ro.CodSala === +newVal);

      setForm((val) => ({ ...val, nomSala: `${FindSala?.Nombre} - ${FindSala?.Ubicacion}` }));
    }
  };


  // funcion para ir la semana anterior
  const handlePreviousWeek = () => {
    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 7)));
  };


  // funcion para ir a la siguiente semana
  const handleNextWeek = () => {
    setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 7)));
  };


  // funcion para seleccionar el color
  const handleSelectColor = (newVal = "") => {
    setForm((val) => ({ ...val, bgColor: newVal }));
    setArrColor((val) => {
      return val.map((color) => {
        if (newVal === color.value) {
          return { ...color, selected: true };
        }
        return { ...color, selected: false };
      });
    });
  };


  const getStartOfWeek = (date) => {
    const Start = new Date(date);
    Start.setDate(date.getDate() - date.getDay());

    return Start;
  };

  if (END_POINT === "") {
    END_POINT = `${urls.roomReserv}?action=0&startWeek=${getStartOfWeek(currentDate).toISOString().split("T")[0]}&language=${lang}&room=${form.location}`;
  }

  // Helper function to convert "HH:MM" to minutes
  const timeToMinutes = (time) => {
    const [hour, minute] = time.split(":").map(Number);
    return hour * 60 + minute;
  };

  // onclick select date
  const handleSelectedSlot = (key = "", render = false) => {

    if (render) {
      return;
    }

    if (!render && reserva) {

      const [date, time] = key.split("T");

      const selectedSlotDateTime = new Date(`${date} ${time}`);

      const HasValue = reserva.filter((event) => {
        const eventStartTime = new Date(`${event.FecDesde.split("T")[0]} ${event.HorDesde}`);
        const eventEndTime = new Date(`${event.FecDesde.split("T")[0]} ${event.HorHasta}`);

        return date === event.FecDesde.split("T")[0] && selectedSlotDateTime.getTime() >= eventStartTime.getTime() && selectedSlotDateTime.getTime() <= eventEndTime.getTime();

      });

      if (HasValue.length) {
        const { Titulo, FecDesde, FecHasta, HorDesde, HorHasta, Descripcion, BGColor } = HasValue[0];
        setForm((val) => ({
          ...val, evento: HasValue, showModal: true, title: Titulo, date: FecDesde,
          dateUntil: FecHasta.split("T")[0], timeFrom: HorDesde, timeUntil: HorHasta,
          description: Descripcion ?? "", bgColor: BGColor, invitados: []
        }));
        setArrColor((val) => {
          return val.map((color) => {
            if (BGColor === color.value) {
              return { ...color, selected: true };
            }
            return { ...color, selected: false };
          });
        });
        return;
      }

    }

    setForm((val) => ({ ...val, evento: null, title: "", description: "", bgColor: "", invitados: [] }));
    setArrColor((val) => {
      return val.map((color) => {
        return { ...color, selected: false };
      });
    });


    // aqui se puede abrir el modal para empezar a interactuar para los eventos
    handleChangeFormVal("showModal", true);
    handleChangeFormVal("date", key.split("T")[0]);
    handleChangeFormVal("dateUntil", key.split("T")[0]);

    let time = key.split("T")[1];

    const [hour, minutes] = time.split(":");
    const HourNext = Number(hour) + 1;

    const Time = hour.length === 1 ? `0${hour}:${minutes}` : `${hour}:${minutes}`;

    let TimeNext = "";

    if (minutes === "30") {
      TimeNext = HourNext.toString().length === 1 ? `0${HourNext}:30` : `${HourNext}:30`;
    } else {
      TimeNext = HourNext.toString().length === 1 ? `0${HourNext}:00` : `${HourNext}:00`;
    }

    handleChangeFormVal("timeFrom", Time);
    handleChangeFormVal("timeUntil", TimeNext);
  };


  const renderTimeSlots = (render = false, date = "") => {
    const Slots = [];

    for (let hour = StartHour; hour < EndHour; hour++) {
      const times = [`${hour}:00`, `${hour}:30`];

      times.forEach((time, index) => {
        const currentTimeInMinutes = timeToMinutes(time);

        Slots.push(
          <div
            key={`${hour}:${index === 0 ? "00" : "30"}`}
            className={`${render ? "padding-slots" : "hover-class slots-width"} slots`}
            // style={{ flexGrow: !render && "1" }}
            onClick={() => handleSelectedSlot(`${date}T${time}`, render)}>
            {render && time}

            {/*  */}
            { // Render events in slots
              !render && reserva && reserva
                .filter((event) => {
                  // Get event date and convert event time range to minutes
                  const eventDate = event.FecDesde.split("T")[0];
                  const eventStart = timeToMinutes(event.HorDesde);
                  const eventEnd = timeToMinutes(event.HorHasta);

                  // Check if slot is within the event range for the selected date
                  return eventDate === date && currentTimeInMinutes >= eventStart && currentTimeInMinutes <= eventEnd;
                })
                .map((event) => (
                  <div key={event.CodSolSala} className="text-hidden-over cont-event" style={{ backgroundColor: event.BGColor }}>
                    <span style={{ display: "block" }}>{event.Titulo}</span>
                    <span style={{ display: "block" }}>{event.HorDesde} - {event.HorHasta}</span>
                  </div>
                ))
            }
          </div>
        );
      });
    }

    return Slots;
  };

  // renderizar los dias
  const renderDays = () => {
    const StartOfWeek = getStartOfWeek(currentDate);
    const Days = [];

    const Today = new Date();
    Today.setHours(0, 0, 0, 0);

    for (let i = 0; i < 7; i++) {
      const day = new Date(StartOfWeek);
      day.setDate(StartOfWeek.getDate() + i); // los dias de la semana seleccionada
      day.setHours(0, 0, 0, 0);

      const Hoy = Today.getTime() === day.getTime();

      Days.push(
        <div key={i} style={{ border: "1px solid #ccc", display: "flex", flexDirection: "column" }}>
          <div style={{ textAlign: "center", padding: "7px 0", fontWeight: "bold", borderBottom: "1px solid #ccc", backgroundColor: Hoy ? "#3b65f5" : "transparent", color: Hoy ? "#ffffff" : "var(--first-color)" }}>
            {DaysOfWeek[i]} {day.getDate()}
          </div>
          {renderTimeSlots(false, day.toISOString().split("T")[0])}
        </div>
      );
    }

    return Days;
  };


  const fetchDataServ = async () => {

    const abortContr = new AbortController();

    setController(abortContr);

    try {
      setLoadingData();

      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortContr.signal
      });

      const data = await response.json();

      handleReloadData();

      if (response.status === 200) {
        setDataState(data);
      } else if (response.status === 401) {
        navigate("/");
      }

      setLoadingData();

    } catch (err) {
      console.log(err);
    }
  };


  // useFetch para la data
  useEffect(() => {

    if (END_POINT !== "") {

      fetchDataServ();

      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }

  }, [END_POINT, getStartOfWeek(currentDate).toISOString().split("T")[0], lang, form.location]);

  // useEffect para recargar la data cuando se crea un evento
  useEffect(() => {

    if (state.reload) {

      fetchDataServ();

      return () => {
        if (controller) {
          controller.abort();
        }
      };

    }

  }, [state.reload]);


  // useEffect para cargar el nombre de la sala
  useEffect(() => {
    if (rooms && rooms.length > 0) {
      const FindSala = rooms.find((ro) => ro.CodSala === +form.location);
      setForm((val) => ({ ...val, nomSala: `${FindSala?.Nombre} - ${FindSala?.Ubicacion}` }));
    }
  }, [rooms]);





  return {
    currentDate,
    form,
    state,
    dataState,
    arrColor,
    handlePreviousWeek,
    handleNextWeek,
    renderTimeSlots,
    renderDays,
    getStartOfWeek,
    handleChangeFormVal,
    handleDeleteInvit,
    handleReloadData,
    handleSelectColor,
    handleSendDataServ,
    handleChangeMultiForm
  };
};