// componente para renderizar las listas de los pendientes de almacen

import { useEffect, useState } from "react";

// components importation
import { Close } from "../icons/AppIcons.jsx";


import { urls } from "../../api-urls/api-urls.js";


import { useTranslation } from "react-i18next";

// global functions
import { renderDate, RenderSchedules, renderTemperature } from "../../global-functions/globalFunctions.js";


import { Link, useNavigate } from "react-router-dom";




export const RenderPendings = ({ action = "", title = "", onClose, lang = "", zone = "", filt = "", token = "" }) => {


  const [controller, setController] = useState(null); // estado para cancelar la peticion al servidor
  const [dataServ, setDataServ] = useState([]);
  const [state, setState] = useState({ isLoading: false, dataVis: false });

  const navigate = useNavigate();

  // destructuring data server
  const { Data } = dataServ && dataServ;

  const [t] = useTranslation("global");


  // fetch de datos
  const fetchDataServ = async () => {

    const abortCont = new AbortController();
    setController(abortCont);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(`${urls.getWarehousePrincipal}?language=${lang}&zone=${zone}&filt=${filt}&act=${action}`, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortCont.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setState((val) => ({ ...val, dataVis: true }));
        setDataServ(data);
      } else if (response.status === 401) {
        return navigate("/");
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (err) {
      console.log(err);
    }
  };


  // useEffect para consultar la data al servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [action, lang, zone]);




  return (
    <div className="background-modal">
      <div className="container-select-customer container-warehouse">
        <div className="modal-header">
          <h4>{title}</h4>
          <button type="button" className="btn-close" onClick={() => onClose("", "")}>
            <Close />
          </button>
        </div>
        <div className="modal-body">
          {
            state.isLoading && (<strong>{t("PartModify.cargando_info")}</strong>)
          }

          {
            state.dataVis && (
              <div className="table-container-mobile">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>#</th>
                      <th>Temp.</th>
                      {
                        ["OrdPorDesp", "CondPendDesp", "PorLlegAlma"].includes(action) && (
                          <th>{t("WareHouseView.order")}</th>

                        )
                      }
                      {
                        action === "OrdPorDesp" && (
                          <th>{t("WareHouseView.requestDate")}</th>
                        )
                      }
                      {
                        action === "PorLlegAlma" && (
                          <th>{t("LogisticView.date")}</th>
                        )
                      }
                      {
                        ["CondPendDesp"].includes(action) && (
                          <>
                            <th>{t("WareHouseView.request")}</th>
                            <th>{t("WareHouseView.comanda")}</th>
                            <th>{t("WareHouseView.requestDate")}</th>
                            <th>{t("WareHouseView.requiredDate")}</th>
                          </>
                        )
                      }

                      {
                        ["NotaRetPorEla", "CondPendDesp", "RetiroParts"].includes(action) && (
                          <th>{t("WareHouseView.serviceId")}</th>
                        )
                      }
                      {
                        ["RetiroParts", "NotaRetPorEla"].includes(action) && (
                          <>
                            <th className="txt-center">{t("RequestPartsView.nota_entrega")}</th>
                            <th>{t("TableParts.parte")}</th>
                          </>
                        )
                      }
                      {
                        action === "RetiroParts" && (
                          <th>{t("WareHouseView.requestDate")}</th>
                        )
                      }
                      <th>{t("WareHouseView.zone")}</th>
                      <th>{t("WareHouseView.customer")}</th>
                      <th>{t("WareHouseView.equipment")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      Data && Data.map((dat) => (
                        <tr key={dat.CodeIndex}>
                          <td className="txt-center">{dat.CodeIndex}</td>
                          <td>
                            <span className={renderTemperature(dat.Prioridad)}></span>
                          </td>
                          {
                            ["OrdPorDesp", "CondPendDesp", "PorLlegAlma"].includes(action) && (
                              <td className="txt-center">
                                <Link className="link-tag" to={dat.Route}>{dat.NumOrden}</Link>
                              </td>
                            )
                          }
                          {
                            action === "PorLlegAlma" && (
                              <td>{renderDate(dat.Fecha, lang)}</td>
                            )
                          }
                          {
                            ["OrdPorDesp"].includes(action) && (
                              <td>{renderDate(dat.FecRequerida, lang)}</td>
                            )
                          }
                          {
                            ["CondPendDesp"].includes(action) && (
                              <>
                                <td className="txt-center">
                                  <Link className="link-tag" to={dat.RouteSol}>{dat.CodSolRepuesto}</Link>
                                </td>
                                <td></td>
                                <td></td>
                                <td>
                                  {renderDate(dat.FecRequerida, lang)} {RenderSchedules(dat.Horario, lang)}
                                  {dat.MSG}
                                </td>
                              </>
                            )
                          }
                          {
                            ["NotaRetPorEla", "CondPendDesp", "RetiroParts"].includes(action) && (
                              <td className="txt-center">
                                <Link className="link-tag" to={`/service/service-call/${dat.CodServicio}`}>{dat.CodServicio}</Link>
                              </td>
                            )
                          }
                          {
                            ["NotaRetPorEla", "RetiroParts"].includes(action) && (
                              <>
                                <td className="txt-center">
                                  <Link className="link-tag" to={dat.Route}>{dat.CodNotEntrega}</Link>
                                </td>
                                <td>{dat.Parte}</td>
                              </>
                            )
                          }
                          {
                            action === "RetiroParts" && (
                              <td>{renderDate(dat.FecIngreso, lang)}</td>
                            )
                          }
                          <td className="txt-center">{dat.Zona}</td>
                          <td>
                            <Link className="link-tag" to={`/customers/customer-detail/${dat.CodCliente}`}>{dat.NomCliente}</Link>
                          </td>
                          <td>
                            <Link className="link-tag" to={`/equipments/equipment-detail/${dat.CodEquipo}`}>{dat.Equipo}</Link>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};