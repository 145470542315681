import React, { useEffect, useState } from "react";


// React router dom
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";


// Urls importation
import { urls } from "../../api-urls/api-urls";

// Global function importation
import { getTheToken, renderDate } from "../../global-functions/globalFunctions";
import { LoadingComp } from "../../components/Loading-comp/LoadingComp";
import { Error404Comp } from "../../components/errors/Error404Comp";


export const NotaRetiroDetail = () => {

  // GET the language
  const language = localStorage.getItem("language") ?? window.navigator.language.slice(0, 2);

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the location
  const location = useLocation();

  // GET the useParams
  const { codSolRep } = useParams();


  // State definition
  const [dataServer, setDataServer] = useState([]);
  const [notaRetiro, setNotaRetiro] = useState([]);
  const [state, setState] = useState({ isLoading: false, dataVisible: false, unauthorized: false, serverError: false, showModal: false });
  const [stateForm, setStateForm] = useState({});

  // Destructuring dataServer
  const { notRetiro, detSolRep } = dataServer && dataServer;


  // useEffect para traer los datos del servidor
  useEffect(() => {
    // Funcion para el fetch de los datos
    const fetchDataServer = async () => {
      try {
        setState((val) => ({ ...val, isLoading: true }));
        const response = await fetch(`${urls.notRetiro}/${codSolRep}/search?language=${language}`, {
          headers: { "Authorization": getTheToken().token }
        });

        const data = await response.json();

        if (response.status === 200) {
          setDataServer(data);
          setState((val) => ({ ...val, dataVisible: true }));
        }

        setState((val) => ({ ...val, isLoading: false }));

      } catch (error) {
        console.log(error);
      }
    };

    fetchDataServer();
  }, [codSolRep, language]);


  // useEffect para cargar en el estado las notas de retiro
  useEffect(() => {
    if (notRetiro && notRetiro.length) {
      setNotaRetiro(notRetiro);
    }
  }, [notRetiro]);

  console.log(notaRetiro)

  return (
    <>
      {/* Error 404 */}
      {!state.dataVisible && (<Error404Comp navigateTo={"/warehouse/principal"} />)}
      {/* Loading component */}
      {state.isLoading && (<LoadingComp />)}
      <div className="container display-data">

        {
          state.dataVisible && (
            <>
              {/* CARD */}
              < form >
                <div className="card-shadow">
                  {/* FILA */}
                  <div className="row-flex">
                    {/* Solicitud */}
                    <div className="col-7"><strong>Solicitud</strong></div>
                    <div className="col-7"><h4 style={{ color: 'var(--first-color)' }}>{codSolRep}</h4></div>
                    {/* Id servicio */}
                    <div className="col-10"><strong>Id Servicio</strong></div>
                    <div className="col-5"><Link className="link-tag" to={`/service/service-call/${notaRetiro[0]?.CodServicio}`}>{notaRetiro[0]?.CodServicio}</Link></div>
                    {/* Modelo */}
                    <div className="col-10"><strong>Modelo</strong></div>
                    <div className="col-8"><span>{notaRetiro[0]?.equipment[0]?.Modelo}</span></div>
                    {/* Serial */}
                    <div className="col-10"><strong>Serial</strong></div>
                    <div className="col-8"><span>{notaRetiro[0]?.equipment[0]?.Serial}</span></div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Fecha */}
                    <div className="col-7"><strong>Fecha</strong></div>
                    <div className="col-42-29"><span>{renderDate(notaRetiro[0]?.FecIngreso, language)}</span></div>
                    {/* Suplidor */}
                    <div className="col-10"><strong>Suplidor</strong></div>
                    <div className="col-8"><span>{notaRetiro[0]?.equipment[0]?.Marca}</span></div>
                    {/* Instalacion */}
                    <div className="col-10"><strong>Instalación</strong></div>
                    <div className="col-8"><span></span></div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Cliente */}
                    <div className="col-7"><strong>Cliente</strong></div>
                    <div className="col-42-29 text-hidden-over"><span>{notaRetiro[0]?.nomCliente}</span></div>
                    {/* FDO */}
                    <div className="col-10"><strong>FDO</strong></div>
                    <div className="col-8"><span></span></div>
                    {/* OC */}
                    <div className="col-10"><strong>O/C</strong></div>
                    <div className="col-8 border"><span></span></div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Equipo */}
                    <div className="col-7"><strong>Equipo</strong></div>
                    <div className="col-42-29"><span>{language === "en" ? notaRetiro[0]?.equipment[0]?.LineaEn : notaRetiro[0]?.equipment[0]?.Linea} - {language === "en" ? notaRetiro[0]?.equipment[0]?.ModalidadEn : notaRetiro[0]?.equipment[0]?.Modalidad} - {language === "en" ? notaRetiro[0]?.equipment[0]?.TipEquipoEn : notaRetiro[0]?.equipment[0]?.TipEquipo} - {notaRetiro[0]?.equipment[0]?.Modelo} - {notaRetiro[0]?.equipment[0]?.Serial} - {notaRetiro[0]?.equipment[0]?.Marca} - {notaRetiro[0]?.equipment[0]?.NumSistema}</span></div>
                    {/* Contrato */}
                    <div className="col-10"><strong>Contrato</strong></div>
                    <div className="col-8"><span></span></div>
                    {/* Prioridad */}
                    <div className="col-10"><strong>Prioridad</strong></div>
                    <div className="col-8"></div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Ingeniero técnico */}
                    <div className="col-7"><strong>Ingeniero técnico</strong></div>
                    <div className="col-42 29"><span>{notaRetiro[0]?.nomEmpIng}</span></div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Falla */}
                    <div className="col-7"><strong>Falla</strong></div>
                    <div className="col-83-87"><span style={{ textTransform: 'capitalize' }}>{notaRetiro[0]?.Falla.toLowerCase() ?? ""}</span></div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Observacion */}
                    <div className="col-7"><strong>Observación</strong></div>
                    <div className="col-83-87"><span style={{ textTransform: 'capitalize', color: 'var(--green-color)', textDecoration: 'underline' }}>{notaRetiro[0]?.observation.toLowerCase() ?? ""}</span></div>
                  </div>

                  <hr className="m-y-small" />

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Nota de retiro */}
                    <div className="col-7"><strong>Nota de retiro</strong></div>
                    <div className="col-5"><span></span></div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Contacto */}
                    <div className="col-7"><strong>Contacto</strong></div>
                    <div className="col-5">
                      <select name="contact">
                        <option value=""></option>
                        <option value="">Transporte terrestre</option>
                        <option value="">Motorizado</option>
                        <option value="">Taxi</option>
                      </select>
                    </div>
                    {/* De */}
                    <div className="col-10"><strong>De</strong></div>
                    <div className="col-5">
                      <select name="from">
                        <option value=""></option>
                        <option value=""></option>
                        <option value=""></option>
                        <option value=""></option>
                        <option value=""></option>
                      </select>
                    </div>
                  </div>

                  {/* FILA */}
                  <div className="row-flex">
                    {/* Comentario */}
                    <div className="col-7"><strong>Comentario</strong></div>
                    <div className="col-83-87">
                      <textarea
                        className="textarea-description"
                        name="comment" />
                    </div>
                  </div>
                </div>

                {/* Tabla para mostrar las partes */}
                <div className="table-container-mobile m-y-medium">
                  <table className="table">
                    <thead className="table-head">
                      <tr>
                        <th>#</th>
                        <th>Cantidad</th>
                        <th>Parte</th>
                        <th>Descripción</th>
                        <th>Solicitud de retiro</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {
                        detSolRep && detSolRep.map((part, index) => (
                          <tr key={part.CodDetSolRepuesto}>
                            <td className="txt-center">{index + 1}</td>
                            <td className="txt-center">{part.Cantidad}</td>
                            <td><Link className="link-tag">{part.NumParte} {(part.Alternativo)}</Link></td>
                            <td>{part.Descripcion}</td>
                            <td className="col-48-87" style={{ textTransform: 'capitalize' }}>{part.lastComment}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>

                {/* Botones de acción */}
                <div className="row-flex j-content-space-around m-y-medium">
                  <button className="btn-primary">Retirar</button>
                  <button type="reset" className="btn-secondary" onClick={() => navigate(-1, { replace: true })}>Atrás</button>
                </div>
              </form>
            </>
          )
        }
      </div >
    </>
  );
};