// componente para agregar un ingeniero a la programacion

import { useEffect, useState, useContext } from "react";


import { urls } from "../../api-urls/api-urls.js";

import { useTranslation } from "react-i18next";
import { renderDate } from "../../global-functions/globalFunctions.js";

// app icons import
import { Add, TrashIcon } from "../icons/AppIcons.jsx";


// notification context
import { NotificationContext } from "../../context/NotificationContext.jsx";
import { useNavigate } from "react-router-dom";





export const AddScheduleComp = ({ token = "", IdCall = "", lang = "", setEmployee }) => {

  // state definition
  const [controller, setController] = useState(null); // estado para cancelar la data
  const [dataServ, setDataServ] = useState([]); // estado para colocar la data
  const [form, setForm] = useState({ employee: "", fecDesde: "", fecHasta: "", reload: false });

  const { handleNotify } = useContext(NotificationContext);

  const navigate = useNavigate();

  // destructuring data server
  const { employees, empSchedule } = dataServ && dataServ;

  const [t] = useTranslation("global");


  // funcion para modificar el formulario
  const handleChangeForm = (name = "", newVal = "") => {
    setForm((val) => ({ ...val, [name]: newVal }));
  };



  const handlePerformAct = async (act = "", emp = "") => {
    try {

      const EndPoint = {
        "1": `${urls.calls}/${IdCall}/${form.employee}/search?language=${lang}`, // Agregar un empleado
        "2": `${urls.calls}/${emp}/${IdCall}/search?language=${lang}`
      };

      if (act === "1") { // agregar un empleado
        if (!form.fecDesde) {
          return handleNotify(true, t("serviceCard.selec_fec_desde"), true);
        }
      }

      const response = await fetch(EndPoint[act], {
        method: act === "1" ? "POST" : "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        },
        body: JSON.stringify(form)
      });

      const data = await response.json();

      if (response.status === 200) {
        handleNotify(true, data?.msg, false, true);
        setForm((val) => ({ ...val, reload: true }));
      } else if (response.status === 404) {
        handleNotify(true, data?.msg, true);
      } else if (response.status === 401) {
        return navigate("/");
      }

    } catch (err) {
      console.log(err);
    }
  };



  // funcion para hacer el fetch de la data
  const fetchDataServ = async () => {
    const abortContr = new AbortController();

    setController(abortContr);

    try {
      const response = await fetch(`${urls.calls}/employees/add-schedule/${IdCall}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        },
        signal: abortContr.signal
      });

      const data = await response.json();

      setForm((val) => ({ ...val, reload: false }));

      if (response.status === 200) {

        setDataServ(data);
      }

    } catch (err) {
      console.log(err);
    }
  };


  // useFetch para traer la data del servidor
  useEffect(() => {
    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [IdCall]);


  // useEffect para realizar el fetch cuando se agregue un empleado
  useEffect(() => {
    if (form.reload) {
      fetchDataServ();

      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }
  }, [form.reload]);


  // useEffect para setear los empleados en el estado
  useEffect(() => {
    if (employees && employees.length > 0) {
      // setear los empleados en el estados
      setEmployee(employees);
    }
  }, [employees]);




  return (
    <div style={{ margin: '0.8rem 0', padding: '0.8rem 0.5rem 0.8rem 0.5rem', border: '1px solid rgba(0, 0, 0, 0.5)', borderRadius: '0.3rem', boxShadow: 'var(--box-shadow-bottom)' }}>
      <div style={{ marginBottom: '0.5rem' }} className="row-flex d-column">
        <div style={{ width: '8rem' }}>
          <strong style={{ textDecoration: 'underline' }}>{t("newCallComp.scheduling")}</strong>
        </div>
      </div>
      <div className="row-flex">
        <div className="col-8 col-sm-8">
          <strong>{t("newCallComp.ingeniero")}</strong>
        </div>
        <div className="row-flex col-4 col-70-sm gap-small">
          <select
            className="select-tec-serv"
            name="selectTecnico"
            value={form.employee}
            onChange={({ target }) => handleChangeForm("employee", target.value)}>
            <option value=""></option>
            {
              employees && employees.map((emp) => (
                <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
              ))
            }
          </select>
          <button type="button" className="btn-primary" onClick={() => handlePerformAct("1")}>
            <Add />
          </button>
        </div>
      </div>
      {/* Segunda columna */}
      <div className="row-flex">
        <div className="col-8 col-sm-8">
          {/* Esto es para seleccionar la fecha desde */}
          <strong>{t("newCallComp.visitDate")}</strong>
        </div>
        <div className="col-4 col-70-sm">
          <input
            className="input-date-styles"
            type="datetime-local"
            name="fecDesde"
            value={form.fecDesde}
            onChange={({ target }) => handleChangeForm("fecDesde", target.value)} />
        </div>
        <div className="col-8 col-sm-8">
          <strong>{t("newCallComp.visitDateEnd")}</strong>
        </div>
        <div className="col-4 col-70-sm">
          <input
            className="input-date-styles"
            type="datetime-local"
            name="fecHasta"
            value={form.fecHasta}
            onChange={({ target }) => handleChangeForm("fecHasta", target.value)} />
        </div>
      </div>
      {/* FILA */}
      {
        empSchedule && empSchedule.length > 0 && (
          <div className="row-flex d-column">
            {
              empSchedule && empSchedule.map((emp) => (
                <div className="col-6 col-100-sm row-flex gap-small card-shadow" key={emp.CodEmpleado} style={{ margin: '0.2rem 0', border: "1px solid gray", borderRadius: "10px", padding: "0.1rem 0.3rem" }}>
                  <div className="col-6 text-hidden-over"><span>{emp.nomEmpleado}</span></div>
                  <div className="col-3">
                    <span>{renderDate(emp.FecProgramacion, lang)}</span>
                    {
                      emp.FecProHasta && (<span> - {renderDate(emp.FecProHasta, lang)}</span>)
                    }
                  </div>
                  <div className="col-10">
                    <button type="button" className="btn-del" onClick={() => handlePerformAct("2", emp.CodEmpleado)}>
                      <TrashIcon />
                    </button>
                  </div>
                </div>
              ))
            }
          </div>
        )
      }
    </div>
  );
};