// componente para generar el POD

import { useEffect, useState, useContext } from "react";


// components importation


// react router dom importation
import { useLocation, useNavigate } from "react-router-dom";
import { urls } from "../../api-urls/api-urls.js";
import { getTheToken } from "../../global-functions/globalFunctions.js";

// react translation import
import { useTranslation } from "react-i18next";


// context importation
import { NotificationContext } from "../../context/NotificationContext.jsx";
import { PlusCircleIcon } from "../icons/AppIcons.jsx";





export const GeneratePOD = ({ onClose, CodEquip = 0, lang = "" }) => {

  const { handleNotify } = useContext(NotificationContext);

  // GET the react translation
  const [t] = useTranslation("global");

  // GET the useNavigate
  const navigate = useNavigate();

  // GET the useLocation
  const location = useLocation();


  // state definition
  const [dataServer, setDataServer] = useState([]);
  const [containers, setContainers] = useState([]); // estado agregar los contenedores
  const [controller, setController] = useState(null); // controlador para abortar la peticion al servidor
  const [stateForm, setStateForm] = useState({ fecRecep: "", contacto: "", cargo: "", modelo: "" });
  const [notification, setNotification] = useState({ show: false, msg: "", error: false, great: false, success: false });
  const [nextId, setNextId] = useState(1);


  // destructuring data server
  const { container, equipInfo } = dataServer && dataServer;


  // funcion para agregar los contenedores
  const onHandleAddContainers = () => {
    setContainers((prevData) => [
      ...prevData,
      {
        CodEquPOD: nextId,
        CodEquipo: CodEquip,
        Cantidad: nextId,
        Dimension: "",
        Peso: ""
      }
    ]);
    setNextId((val) => val + 1);
  };


  // funcion para modificar las dimensiones
  const onUpdateDimisions = (val, index) => {
    setContainers((prevCont) => {
      return prevCont.map((container) => {
        if (index === container.CodEquPOD) {
          return { ...container, Dimension: val };
        }
        return container;
      });
    });
  };

  // funcion para modificar el peso
  const onUpdatePeso = (val, index) => {
    setContainers((prevCont) => {
      return prevCont.map((container) => {
        if (index === container.CodEquPOD) {
          return { ...container, Peso: val };
        }
        return container;
      });
    });
  };


  // funcion para descargar el POD
  const onHandlePrintPOD = async () => {

    try {
      const response = await fetch(`${urls.createPOD}/${CodEquip}/search?language=${lang}&createPOD=1`, {
        headers: {
          "Authorization": getTheToken().token,
          "Accept": "application/pdf"
        }
      });

      if (response.status === 200) {
        const blob = await response.blob();

        const blobUrl = URL.createObjectURL(blob);

        // crear un enlace oculto
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = blobUrl;
        a.download = "POD";
        document.body.appendChild(a);

        // simular click
        a.click();

        // limpiar la url y el enlace de descarga
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);

        // mostrar notificacion
        handleNotify(true, t("newCallComp.descarga_archivo"), false, true);

      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        const data = await response.json();

        handleNotify(true, data?.msg, true);
      } else if (response.status === 404) {
        const data = await response.json();

        handleNotify(true, data?.msg, true);
      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para manejar el formulario
  const onHandleSaveForm = async (event) => {
    event.preventDefault();

    // verificar si todos los campos están completos
    let dimen = 0;
    let peso = 0;

    if (containers.length === 0) {
      return handleNotify(true, t("serviceCard.cant_conte"), true);
    }

    for (const inf of containers) {
      if (inf.Dimension.trim() === "") {
        dimen += 1;
      }
      if (inf.Peso.trim() === "") {
        peso += 1;
      }
    }

    if (dimen > 0 || peso > 0) {
      return handleNotify(true, t("serviceCard.camp_llenar"), true);
    }

    if (stateForm.contacto.trim() === "") {
      return handleNotify(true, t("serviceCard.pers_cont"), true);
    }

    if (stateForm.cargo.trim() === "") {
      return handleNotify(true, t("serviceCard.indi_cargo"), true);
    }

    if (stateForm.fecRecep === "") {
      return handleNotify(true, t("serviceCard.fec_recep"), true);
    }

    const finalObj = { stateForm, containers };

    try {
      const response = await fetch(`${urls.createPOD}/${CodEquip}/search?language=${lang}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalObj)
      });

      const data = await response.json();

      if (response.status === 200) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, great: true }));
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para solicitar los datos al servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    setController(abortController);
    try {
      const response = await fetch(`${urls.createPOD}/${CodEquip}/search?language=${lang}&createPOD=2`, {
        headers: {
          "Authorization": getTheToken().token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
      } else if (response.status === 401) {
        localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        setNotification((val) => ({ ...val, show: true, msg: data?.msg, error: true }));
      }

    } catch (error) {
      console.log(error);
    }
  };

  // useEffect para solicitar la data al servidor
  useEffect(() => {
    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [lang, CodEquip]);


  // useEffect para limpiar el estado de las notificaciones
  useEffect(() => {
    const timer = setTimeout(() => {
      setNotification({ show: false, msg: "", error: false, great: false, success: false });
    }, 5000);

    return () => {
      clearTimeout(timer);
    };

  }, [notification.show]);


  // useEffect para cargar la data en el estado
  useEffect(() => {
    if (equipInfo && equipInfo.length > 0) {
      setStateForm((val) => ({ ...val, contacto: equipInfo[0]?.Contacto ?? "", cargo: equipInfo[0]?.Cargo ?? "", fecRecep: equipInfo[0]?.FecAmbiente?.slice(0, 10) ?? "", modelo: equipInfo[0]?.NomModelo }));
    }
  }, [equipInfo]);

  // useEffect para cargar el array de los container que vienen de la db
  useEffect(() => {
    if (container && container.length > 0) {
      setContainers(container);

      setNextId(container.length + 1);
    }
  }, [container]);



  return (
    <div className="background-modal" onClick={() => onClose("modalPOD", false)}>
      <div className="container-modal" onClick={(event) => event.stopPropagation()}>
        <form onSubmit={onHandleSaveForm}>
          {/* fila */}
          <div className="row-flex">
            <div className="col-7">
              <strong>{t("serviceCard.modelo")}</strong>
            </div>
            <div className="col-3">
              <strong>{stateForm.modelo}</strong>
            </div>
            <div className="col-7">
              <strong>{t("serviceCard.fec_recepc")}</strong>
            </div>
            <div className="col-3">
              <input
                className="input-date-styles"
                type="date"
                name="fecRecep"
                value={stateForm.fecRecep}
                onChange={({ target }) => setStateForm((val) => ({ ...val, fecRecep: target.value }))} />
            </div>
          </div>
          {/* fila */}
          <div className="row-flex">
            <div className="col-7">
              <strong>{t("serviceCard.pers_contact")}</strong>
            </div>
            <div className="col-3">
              <input
                className="input-text-control"
                type="text"
                name="perCont"
                value={stateForm.contacto}
                onChange={({ target }) => setStateForm((val) => ({ ...val, contacto: target.value }))}
                autoComplete="off" />
            </div>
            <div className="col-7">
              <strong>{t("customerView.cargo")}</strong>
            </div>
            <div className="col-3">
              <input
                className="input-text-control"
                type="text"
                name="position"
                value={stateForm.cargo}
                onChange={({ target }) => setStateForm((val) => ({ ...val, cargo: target.value }))}
                autoComplete="off" />
            </div>
          </div>
          {/* fila */}
          <div className="row-flex">
            <div className="col-7">
              <strong>{t("serviceCard.agreg_cont")}</strong>
            </div>
            <div className="col-7" style={{ display: "flex", alignItems: "center" }}>
              <button type="button" style={{ display: "flex", padding: "0.15rem", cursor: "pointer" }} onClick={onHandleAddContainers}>
                <PlusCircleIcon fillP="var(--first-color)" />
              </button>
            </div>
          </div>

          {/* tabla para mostrar los contenedores */}
          {
            containers && containers.length > 0 && (
              <div className="table-container-mobile m-y-small">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th>Contenedor</th>
                      <th>Dimensión (cm)</th>
                      <th>Peso (Kg.)</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      containers && containers.map((con) => (
                        <tr key={con.CodEquPOD}>
                          <td className="txt-center">{con.CodEquPOD}</td>
                          <td>
                            <input
                              className="input-text-control txt-center"
                              type="text"
                              name={`dimen${con.CodEquPOD}`}
                              value={con.Dimension}
                              onChange={({ target }) => onUpdateDimisions(target.value, con.CodEquPOD)}
                              autoComplete="off" />
                          </td>
                          <td>
                            <input
                              className="input-text-control txt-center"
                              type="text"
                              name={`weigh${con.CodEquPOD}`}
                              value={con.Peso}
                              onChange={({ target }) => onUpdatePeso(target.value, con.CodEquPOD)}
                              autoComplete="off" />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            )
          }

          <div className="row-flex j-content-space-around m-y-small">
            <button type="submit" className="btn-primary">{t("newCallComp.save")}</button>
            <button type="button" className="btn-success" onClick={onHandlePrintPOD}>{t("Viaticos.imprimir")}</button>
            <button type="reset" className="btn-secondary" onClick={() => onClose("modalPOD", false)}>{t("serviceCard.cerrar")}</button>
          </div>
        </form>
      </div>
    </div>
  );
};