// componente para buscar las solicitudes de consumo de tarjeta

import { useEffect, useState } from "react";
import { urls } from "../../api-urls/api-urls";
import { useNavigate } from "react-router-dom";





export const SearchConsumpRequeComp = ({ onClose, params, setParams, token, lang, newStateData, onNotify }) => {

  // GET the useNavigate
  const navigate = useNavigate();

  // state definition
  const [dataServ, setDataServ] = useState([]); // estado para cargar la data del servidor
  const [controller, setController] = useState(null);
  const [stateForm, setStateForm] = useState({ codSol: params.get("cod_sol") ?? "", empleado: params.get("nom_empl") ?? "", consuFin: params.get("consu_fin") ?? "", empresa: params.get("empre") ?? "", geren: params.get("geren") ?? "", etapa: params.get("etapa") ?? "0" });
  const [isLoading, setIsLoading] = useState(false);


  // destructuring data
  const { empresa, gerencia, etapas, empPerm } = dataServ && dataServ;


  // funcion para manejar el cambio de los inputs
  const onHandleChangeVal = (type = 1, newVal = "") => {
    params.set("filt", 1);
    switch (type) {
      case 1: // solicitud
        if (isNaN(newVal)) {
          return;
        }
        params.set("cod_sol", newVal);
        setParams(params);
        setStateForm((val) => ({ ...val, codSol: newVal }));
        break;
      case 2: // empleado
        params.set("nom_empl", newVal);
        setParams(params);
        setStateForm((val) => ({ ...val, empleado: newVal }));
        break;
      case 3: // consumidor final
        params.set("consu_fin", newVal);
        setParams(params);
        setStateForm((val) => ({ ...val, consuFin: newVal }));
        break;
      case 4: // empresa
        params.set("empre", newVal);
        setParams(params);
        setStateForm((val) => ({ ...val, empresa: newVal }));
        break;
      case 5: // gerencia
        params.set("geren", newVal);
        setParams(params);
        setStateForm((val) => ({ ...val, geren: newVal }));
        break;
      case 6: // etapa
        params.set("etapa", newVal);
        setParams(params);
        setStateForm((val) => ({ ...val, etapa: newVal }));
        break;
    }
  };


  // funcion para buscar en el servidor
  const onHandleSearchForm = async (event) => {
    event.preventDefault();

    const END_POINT = `${urls.autorizaciones}?language=${lang}&action=4&codSol=${stateForm.codSol}&nom_empl=${stateForm.empleado}&consu_final=${stateForm.consuFin}&etapa=${stateForm.etapa}&nom_empr=${stateForm.empresa}&gerencia=${stateForm.geren}&filt=${params.get("filt") ?? "0"}`;

    try {
      setIsLoading(true);
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        newStateData(data?.pendings);
        onNotify("");
        onClose();
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        newStateData([]);
        onNotify(data?.msg);
        onClose();
      }

      setIsLoading(false);

    } catch (error) {
      console.log(error);
    }
  };


  // funcion para traer los datos iniciales para consultar la informacion
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);

    const END_POINT = `${urls.autorizaciones}?language=${lang}&action=12`;

    try {
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServ(data);
        // console.log(data);
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      }

    } catch (error) {
      console.log(error);
    }
  };



  // useEffect para traer la data del servidor
  useEffect(() => {

    fetchDataServ();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);



  // useEfffect para cargar los datos en el formulario de busqueda
  useEffect(() => {
    if (empPerm && empPerm.length > 0) {
      if (!params.get("filt")) {
        // cargar los datos del primer renderizado
        if (empPerm[0]?.Coordinador && empPerm[0]?.CodPerfil !== 7) {
          setStateForm((val) => ({ ...val, etapa: "0" }));
        } else {
          if (empPerm[0]?.CodPerfil === 7) {
            setStateForm((val) => ({ ...val, etapa: "0", geren: empPerm[0]?.CodGerencia.toString() ?? "" }));
          } else {
            setStateForm((val) => ({ ...val, etapa: "0" }));
          }
        }
      }
    }
  }, [empPerm]);






  return (
    <div className="background-modal">
      <form onSubmit={onHandleSearchForm} className="container-select-customer">
        <div className="modal-header">
          <h4>Búsqueda</h4>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div className="modal-body">

          <div className="row-flex">
            <div className="col-7">
              <strong>Número solicitud</strong>
            </div>
            <div className="col-36-58">
              <input
                className="input-text-control"
                type="text"
                name="codSol"
                value={stateForm.codSol}
                onChange={({ target }) => onHandleChangeVal(1, target.value)} />
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7">
              <strong>Empleado</strong>
            </div>
            <div className="col-36-58">
              <input
                className="input-text-control"
                type="text"
                name="employee"
                value={stateForm.empleado}
                onChange={({ target }) => onHandleChangeVal(2, target.value)} />
            </div>
            <div className="col-7">
              <strong>Consumidor final</strong>
            </div>
            <div className="col-3">
              <input
                className="input-text-control"
                type="text"
                name="consuFin"
                value={stateForm.consuFin}
                onChange={({ target }) => onHandleChangeVal(3, target.value)} />
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7">
              <strong>Empresa</strong>
            </div>
            <div className="col-36-58">
              <select
                name="empr"
                value={stateForm.empresa}
                onChange={({ target }) => onHandleChangeVal(4, target.value)}>
                <option value=""></option>
                {
                  empresa && empresa.map((empr) => (
                    <option key={empr.CodEmpresa} value={empr.CodEmpresa}>{empr.Nombre}</option>
                  ))
                }
              </select>
            </div>
            <div className="col-7">
              <strong>Gerencia</strong>
            </div>
            <div className="col-3">
              <select
                name="geren"
                value={stateForm.geren}
                onChange={({ target }) => onHandleChangeVal(5, target.value)}>
                <option value=""></option>
                {
                  gerencia && gerencia.map((ger) => (
                    <option key={ger.CodGerencia} value={ger.CodGerencia}>{ger.NomGerencia}</option>
                  ))
                }
              </select>
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7">
              <strong>Etapa</strong>
            </div>
            <div className="col-36-58">
              <select
                name="step"
                value={stateForm.etapa}
                onChange={({ target }) => onHandleChangeVal(6, target.value)}>
                <option value=""></option>
                <option value="0">Pendientes</option>
                {
                  etapas && etapas.map((eta) => (
                    <option key={eta.Id} value={eta.Id}>{lang === "en" ? eta.NombreEn : eta.Nombre}</option>
                  ))
                }
              </select>
            </div>
          </div>

        </div>
        <div className="modal-footer j-content-space-around">
          <button type="submit" className="btn-success">Buscar</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
        </div>
      </form>
    </div>
  );
};