// componente para crear un evento

import { useContext, useState } from "react";

import { ClockIcon, Close, LocationIcon, PeopleIcon, BodyTextIcon, PaletteColorIcon, CheckMark, ArrowRightIcon } from "../icons/AppIcons.jsx";


// import useFetchReserva
import { useFetchReserva } from "../../components/customs/reservations/useFetchReserva.js";

// urls importation
import { urls } from "../../api-urls/api-urls.js";


import { getTheToken, HorasReserv } from "../../global-functions/globalFunctions.js";


// notification context
import { NotificationContext } from "../../context/NotificationContext.jsx";




export const CreateEvent = ({ lang = "", form = {}, onChangeVal, handleSelectColor, arrColor, handleChangeMultiForm, onReload }) => {

  // End point
  const EndPoint = `${urls.roomReserv}?action=1&language=${lang}`;

  const [errors, setErrors] = useState([]);

  // GET the useFetch
  const { dataState, state, handleDeleteInvit, handleReloadData, handleSendDataServ } = useFetchReserva(EndPoint, getTheToken().token);

  const { employees, rooms, empPerm } = dataState && dataState;


  // GET the notification
  const { handleNotify } = useContext(NotificationContext);

  // funcion para guardar el evento
  const handleSaveEvent = async (event) => {
    event.preventDefault();

    const id = event.nativeEvent.submitter.id;

    const flag = {
      "save": "1",
      "delete": "2"
    };


    try {
      handleSendDataServ(true);
      const response = await fetch(`${EndPoint}&flag=${flag[id]}`, {
        method: "POST",
        headers: {
          "Authorization": getTheToken().token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(form)
      });

      const data = await response.json();

      if ([200, 201].includes(response.status)) {
        onReload();
        handleNotify(true, data?.msg, false, true);
        setErrors([]);
        if (response.status === 201) { // actualizar
          handleChangeMultiForm({ title: "", invitados: [], bgColor: "", description: "" });
        }
      } else {
        handleNotify(true, data?.msg, true);
        if (data?.errors) {
          setErrors(data?.errors);
        }
      }

      handleSendDataServ();

    } catch (err) {
      console.log(err);
    }
  };



  return (
    <div className="background-modal" onClick={() => onChangeVal("showModal", false)}>
      <form className="container-select-customer" onClick={(event) => event.stopPropagation()} onSubmit={handleSaveEvent}>
        <div className="modal-header">
          <h3>
            {
              !form.evento ? ("Crear evento") : ("Detalle del evento")
            }
          </h3>
          <button type="button" className="btn-close" onClick={() => onChangeVal("showModal", false)}>
            <Close />
          </button>
        </div>
        <div className="modal-body">
          <div className="row-flex">
            <div className="col-7">
              <strong>
                {
                  !form.evento ? "Añade un título" : "Cambiar título"
                }
              </strong>
            </div>
            <div className="flex-grow col-80">
              <input
                style={{ borderColor: errors.includes("title") && "red" }}
                className="input-text-control"
                type="text"
                name="title"
                value={form.title}
                onChange={({ target }) => onChangeVal("title", target.value)} />
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7 row-flex align-c flex-end">
              <ClockIcon />
            </div>
            <div className="col-80  align-c flex-grow gap-small row-flex">
              {/* <div className="col-6 flex-grow gap-small row-flex"> */}
              {
                !form.evento && (
                  <strong>{new Date(form.date).toLocaleDateString("es", { timeZone: "UTC", weekday: "short", month: "short", day: "numeric" })}</strong>
                )
              }
              {
                form.evento && (
                  <input
                    className="input-date-styles-reser"
                    type="date"
                    name="date"
                    value={form.date.split("T")[0]}
                    onChange={({ target }) => onChangeVal("date", target.value)} />
                )
              }
              <select
                className="select-time"
                name="timeFrom"
                value={form.timeFrom}
                onChange={({ target }) => onChangeVal("timeFrom", target.value)}>
                {
                  HorasReserv.map((hor) => (
                    <option key={hor.hor} value={hor.hor}>{hor.horDis}</option>
                  ))
                }
              </select>
              <ArrowRightIcon />
              <input
                className="input-date-styles-reser"
                type="date"
                name="dateUnt"
                value={form.dateUntil}
                onChange={({ target }) => onChangeVal("dateUntil", target.value)} />

              <select
                className="select-time"
                name="timeUnt"
                value={form.timeUntil}
                onChange={({ target }) => onChangeVal("timeUntil", target.value)}>
                {
                  HorasReserv.map((hor) => (
                    <option key={hor.hor} value={hor.hor}>{hor.horDis}</option>
                  ))
                }
              </select>
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7 row-flex align-c flex-end">
              <PaletteColorIcon />
            </div>
            <div className="col-6">
              <ul className="list-color-picker" style={{ border: errors.includes("bgColor") && "1px solid red" }}>
                {
                  arrColor.map((col) => (
                    <li key={col.value} style={{ backgroundColor: col.value }} onClick={() => handleSelectColor(col.value)}>
                      {
                        col.selected && (<CheckMark fillP="#ffffff" heightP="20" widthP="20" />)
                      }
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7 row-flex align-c flex-end">
              <PeopleIcon />
            </div>
            <div className="col-6">
              <select
                name="invit"
                value={form.invitado}
                onChange={({ target }) => onChangeVal("", target.value, true)}>
                <option value="">--Añadir invitados--</option>
                {
                  employees && employees.filter((emp) => !form.invitados.includes(emp.CodEmpleado)).map((emp) => (
                    <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                  ))
                }
              </select>
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7 row-flex align-c flex-end">
              <LocationIcon />
            </div>
            <div className="col-6">
              <select
                style={{ borderColor: errors.includes("location") && "red" }}
                name="locat"
                value={form.location}
                onChange={({ target }) => onChangeVal("location", target.value)}>
                <option value="">--Añadir ubicación--</option>
                {
                  rooms && rooms.map((room) => (
                    <option key={room.CodSala} value={room.CodSala}>{room.Nombre}</option>
                  ))
                }
              </select>
            </div>
          </div>

          <div className="row-flex">
            <div className="col-7 row-flex align-c flex-end">
              <BodyTextIcon />
            </div>
            <div className="col-6">
              <textarea
                className="textarea-description"
                name="descript"
                value={form.description}
                onChange={({ target }) => onChangeVal("description", target.value)} />
            </div>
          </div>

          {
            form.invitados.length > 0 && (
              <div className="fade-animation">
                <h5>Invitados</h5>

                <ul className="m-y-small li-invit">
                  {
                    employees && employees.filter((emp) => form.invitados.includes(emp.CodEmpleado)).map((emp) => (
                      <li key={emp.CodEmpleado} className="fade-animation">
                        {emp.NomEmpleado}
                        {
                          !form.evento && (
                            <button className="btn-close" onClick={() => handleDeleteInvit(emp.CodEmpleado)}>
                              <Close />
                            </button>
                          )
                        }
                      </li>
                    ))
                  }
                </ul>
              </div>
            )
          }
        </div>
        <div className="modal-footer j-content-space-around">
          <button id="save" type={state.sending ? "button" : "submit"} className={`btn-primary ${state.sending && "disabled"}`}>{state.sending ? "Guardando..." : "Guardar"}</button>
          {
            form.evento && (<button id="delete" type="submit" className="btn-danger">Eliminar</button>)
          }
          <button type="reset" className="btn-secondary" onClick={() => onChangeVal("showModal", false)}>Cerrar</button>
        </div>
      </form>
    </div>
  );
};