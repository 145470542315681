// custom hook para el detalle de las llamadas

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";



export const useFetchServCall = (EndPoint = "", token = "", lang = "", IdCall = "") => {

  // STATE DEFINITION
  const [form, setForm] = useState({ responsibleEmployee: "", step: "", equipment: "", problem: "", idTipServicio: "", action: "", fecDesde: "", fecHasta: "", comment: "", fecVisit: "", priority: "", tecRespon: "", employee: "", employeeProgra: "", idServic: "" });
  const [state, setState] = useState({ dataVisible: false, unAuthorized: false, modalUploadCA: false, modalUploadServRep: false, modalSearchCall: false, codRepServ: 0, modalPOD: false, isLoading: false, sending: false, reload: false, modalDocs: false, modalCot: false });
  const [controller, setController] = useState(null); // controlador para abortar la peticion al servidor
  const [dataServ, setDataServ] = useState([]); // estado para cargar la data que viene del servidor
  const [stateServCall, setStateServCall] = useState([]); // estado para cargar la llamada


  const navigate = useNavigate();

  // destructuring data server
  const { idCallDetail } = dataServ && dataServ;


  // etapas
  const ETAPAS = {
    // DEFINICION DE CONSTANTE DE ETAPAS DE SERVICIO 
    ASIG_TECNICO: 2, // Asignacion de tecnico
    SOL_REPUESTO: 10, // Solicitud de repuesto
    APRO_TECNICA: 11, // aprobacion tecnica
    APRO_ADMIN: 12, // aprobacion administrativa
    SOL_PRECIO: 13, // Solicitud de precio
    COTIZACION: 14, // Cotizacion
    APRO_SOPORTE: 18, // Aprobacion del soporte
    ORDEN_COMPRA: 20, // Orden de compra
    ESPERA_REPUESTO: 21, // Espera de repuesto
    CERRAR_SERVICIO: 25, // Cerrar Servicio
    PIEZA_PERDIDA: 28, // Pieza perdida
    ACUSE_RECIBO: 30, // Acuse de recibo
    GUIA_DESPA: 31, // Guia de despacho
    RECIBO_ALMACEN: 32, // Recibo de almacen
    FEC_DESPACHO: 36, // Fecha de despacho
    EN_TRANSITO: 37, // En transito
    NACIONALIZACION: 38, // Nacionalizacion
    CONDUCE: 40, // Conduce
    NOTA_RETIRO: 43, // Nota de retiro
    SE_ENTREG_REP_CLIENTE: 46, // Se entregó repuesto al cliente
    REC_POD: 78, // Recepcion del POD
    EQUIP_BAJO_OBSER: 22, // Equipo bajo observacion
    CULMINADO: 99, // Culminado
    CERRAR_APLI: 61, // Cerrar aplicacion
  };

  // etapas genericas
  const GENERIC_STEPS = [
    ETAPAS.SOL_REPUESTO,
    ETAPAS.APRO_TECNICA,
    ETAPAS.APRO_ADMIN,
    ETAPAS.SOL_PRECIO,
    ETAPAS.ORDEN_COMPRA,
    ETAPAS.COTIZACION,
    ETAPAS.APRO_SOPORTE,
    ETAPAS.ESPERA_REPUESTO,
    ETAPAS.SE_ENTREG_REP_CLIENTE,
    ETAPAS.ACUSE_RECIBO,
    ETAPAS.GUIA_DESPA,
    ETAPAS.RECIBO_ALMACEN,
    ETAPAS.FEC_DESPACHO,
    ETAPAS.EN_TRANSITO,
    ETAPAS.NACIONALIZACION,
    ETAPAS.PIEZA_PERDIDA,
    ETAPAS.NOTA_RETIRO,
    ETAPAS.CONDUCE
  ]; // en la renderizacion buscar por (ACCIONES PARA ETAPAS GENERICAS)

  // TIPOS DE SERVICIO
  const INSTALL = 3; // INSTALACION
  const APP = 5; // APLICACIONES
  const PRE_INSTALL = 10; // Pre instalacion
  const serviceTypes = [INSTALL, APP, PRE_INSTALL];


  // Definicion de funciones
  // funcion para cambiar la temperatura
  const handleChangeTemp = (newVal = "") => {
    setForm((val) => ({ ...val, priority: newVal }));
  };

  // funcion para inhabilitar el boton de guardar
  const handleChangeState = (name = "", newVal) => {
    setState((val) => ({ ...val, [name]: newVal }));
  };


  // funcion para cambiar los valores del formulario
  const handleChangeForm = (name = "", newVal = "") => {
    setForm((val) => ({ ...val, [name]: newVal }));
  };


  // funcion para abrir y cerrar el modal para cargar el reporte de servicio
  const handleOpenCloseServReport = (CodRepServ = 0) => {
    setState((val) => ({ ...val, modalUploadServRep: !state.modalUploadServRep, codRepServ: CodRepServ }));
  };


  // Funcion que realiza el fetch al servidor (para traer la data del servidor)
  const fetchDataServ = async () => {
    const abortController = new AbortController();

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(EndPoint, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      // console.clear()

      const data = await response.json();

      handleChangeState("reload", false);

      if (response.status === 200) {
        setState((val) => ({ ...val, dataVisible: true }));
        setDataServ(data);
      } else if (response.status === 401) {
        // localStorage.setItem("lastNav", location.pathname);
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) { // no autorizado a ingresar a esta ruta
        setState((val) => ({ ...val, dataVisible: false, unAuthorized: true }));
      } else if (response.status === 400) {
        // setBadRequestError({ show: true, msg: t("newCallComp.estructura_url"), url: true, urlMsg: t("newCallComp.url_msg"), otherMsg: t("newCallComp.other_msg_url") });
      } else if (response.status === 500) {
        // ocurrió un error en el servidor
        // setNotification((val) => ({ ...val, show: true, msg: data?.msg, errorNoti: true }));
      }
      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para traer los datos del servidor
  useEffect(() => {

    fetchDataServ();

    document.title = lang === "en" ? "ID Detail - GIS APP" : "Detalle del ID - GIS APP";

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, [IdCall, lang]);


  // useEffect para recargar la data
  useEffect(() => {
    if (state.reload) {
      fetchDataServ();

      return () => {
        if (controller) {
          controller.abort();
        }
      };
    }
  }, [state.reload]);


  // useEffect para cambiar el estado 
  useEffect(() => {
    if (idCallDetail && idCallDetail.length > 0) {
      const etapaCerrada = idCallDetail[0]?.IdEtapa === 99 ? "" : idCallDetail[0]?.IdEtapa;
      setForm((val) => ({
        ...val, responsibleEmployee: idCallDetail[0]?.CodEmpResponsable, step: etapaCerrada, equipment: idCallDetail[0]?.CodEquipo,
        problem: idCallDetail[0]?.Problema, idTipServicio: idCallDetail[0]?.IdTipServicio, action: "", fecDesde: "", fecHasta: "", comment: "", fecVisit: "",
        priority: idCallDetail[0]?.Prioridad.toString(), tecRespon: idCallDetail[0]?.IdEtapa === ETAPAS.ASIG_TECNICO ? idCallDetail[0]?.CodEmpResponsable : ""
      }));

      setStateServCall(idCallDetail);
    }
  }, [idCallDetail]);


  // useEffect para cuando la etapa sea 99 no se muestre nada en el select
  useEffect(() => {
    if (Number(form.step) === 99) {
      setForm((val) => ({ ...val, step: "" }));
    }
  }, [form.step]);




  return {
    form,
    state,
    dataServ,
    stateServCall,
    ETAPAS,
    handleChangeForm,
    handleChangeTemp,
    handleChangeState,
    handleOpenCloseServReport
  };
};