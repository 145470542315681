// componente para cargar los archivos de servicio
import { useState, useContext } from "react";



// urls imports
import { urls } from "../../api-urls/api-urls.js";


// notification context
import { NotificationContext } from "../../context/NotificationContext.jsx";
import { useTranslation } from "react-i18next";




export const UploadServiceDocs = ({ lang = "", token = "", CodEqp = 0, onNotify }) => {

  const { handleNotify } = useContext(NotificationContext);


  //`${urls.serviceReport}?codRepServ=${codRepServ}&language=${lang}` ---> Para cargar el reporte de servicio

  const [t] = useTranslation("global");

  // state definition
  const [doc, setDoc] = useState(null); // estado para cargar los reportes
  const [form, setForm] = useState("");

  // funcion para cargar el archivo
  const handleFileChange = ({ target }) => {
    const file = target.files[0];
    setDoc(file);
  };


  // funcion para subir el formulario al servidor
  const handleUploadDoc = async (event) => {
    event.preventDefault();

    try {

      if (doc) {

        const formData = new FormData();

        formData.append("file", doc);

        // se envia el archivo al servidor
        const response = await fetch(`${urls.equipments}/${CodEqp}/search?language=${lang}&document=${form}`, {
          method: "POST",
          headers: {
            "Authorization": token
          },
          body: formData
        });

        const data = await response.json();

        if (response.status === 200) {
          setDoc(null);
          onNotify("reload", true);
          handleNotify(true, data?.msg, false, true);
        } else if (404) {
          handleNotify(true, data?.msg, true);
        }

      } else {
        handleNotify(true, t("serviceCard.no_archivo"), true);
      }

    } catch (errr) {
      console.log(errr);
    }
  };


  return (
    <div className="background-modal" onClick={() => onNotify("modalDoc", false)}>
      <div className="container-modal-upload-docs" onClick={(event) => event.stopPropagation()}>
        <form onSubmit={handleUploadDoc}>
          <div className="row-flex">
            <div className="col-6">
              <strong>{t("serviceCard.doc_subir")}</strong>
            </div>
            <div className="col-6 text-hidden-over">
              <input
                type="file"
                name="file"
                accept=".pdf"
                onChange={(event) => handleFileChange(event)} />
            </div>
          </div>
          <div className="row-flex">
            <div className="col-6">
              <strong>{t("serviceCard.tip_doc")}</strong>
            </div>
            <div className="col-6">
              <select
                name="docuType"
                value={form}
                onChange={({ target }) => setForm(target.value)}>
                <option value=""></option>
                <option value="1">CA</option>
                <option value="2">POD</option>
                <option value="3">Packing list</option>
                <option value="4">Aplicación 1</option>
                <option value="5">Aplicación 2</option>
                <option value="6">Aplicación 3</option>
              </select>
            </div>
          </div>
          <div>
            <span className="span txt-muted">{t("serviceCard.formato")}</span>
          </div>
          <div className="row-flex j-content-space-around m-y-medium">
            <button type="submit" className="btn-primary">{t("serviceCard.subir")}</button>
            <button type="reset" className="btn-secondary" onClick={() => onNotify("modalDocs", false)}>{t("LogisticViewComp.cerrar")}</button>
          </div>
        </form>
      </div>
    </div>
  );
};