// este componente es para cargar los reportes de servicio

import { useState, useContext } from "react";


// urls imports
import { urls } from "../../api-urls/api-urls.js";

// global function import
import { getTheToken } from "../../global-functions/globalFunctions.js";

// notification context
import { NotificationContext } from "../../context/NotificationContext.jsx";



export const UploadServReportComp = ({ codRepServ, onSendValue, onNotify, lang = "" }) => {


  const { handleNotify } = useContext(NotificationContext);


  // state definition
  const [servReportFile, setServReportFile] = useState(null);



  // funcion para cargar el archivo
  const handleFileChange = ({ target }) => {
    const file = target.files[0];
    setServReportFile(file);
  };

  // funcion para cerrar el modal y enviar el valor al padre
  const onHandleCloseModal = (value) => {
    onSendValue(value);
  };


  // funcion para manejar el envio del formulario
  const onHandleUploadDoc = async (event) => {
    event.preventDefault();

    try {
      if (servReportFile) {
        const formData = new FormData();

        formData.append("file", servReportFile);

        // se envia el archivo al servidor
        const response = await fetch(`${urls.serviceReport}?codRepServ=${codRepServ}&language=${lang}`, {
          method: "POST",
          headers: { "Authorization": getTheToken().token },
          body: formData
        });

        const data = await response.json();

        if (response.status === 200) {
          setServReportFile(null);
          onNotify("reload", true);
          handleNotify(true, data?.msg, false, true);
        } else if (404) {
          handleNotify(true, data?.msg, true);
        }

      } else {
        // no hay nada
        handleNotify(true, "No se ha cargado ningún archivo.", true);
      }

    } catch (error) {
      console.log(error);
    }
  };


  return (
    <>
      <div className="container-modal-upload-docs" onClick={(event) => event.stopPropagation()}>
        <form onSubmit={onHandleUploadDoc}>
          <div className="row-flex">
            <div className="col-6">
              <strong>Documento a subir</strong>
            </div>
            <div className="col-6 text-hidden-over">
              <input
                type="file"
                name="file"
                accept=".pdf, .jpg"
                onChange={handleFileChange} />
            </div>
          </div>
          <div className="row-flex">
            <div className="col-6">
              <strong>Documentos soportados</strong>
            </div>
            <div className="col-6">
              <span className="span txt-muted">PDF | JPG</span>
            </div>
          </div>
          <div className="row-flex j-content-space-around m-y-small">
            <button type="submit" className="btn-primary">Subir</button>
            <button type="reset" className="btn-secondary" onClick={() => onHandleCloseModal(false)}>Cerrar</button>
          </div>
        </form>
      </div>
    </>
  );
};