
import React, { useEffect, useContext } from "react";

// COMPONENTS IMPORTATION
import { LoadingComp } from "../../components/Loading-comp/LoadingComp.jsx";
import { Add, TrashIcon, SendCheckIcon } from "../../components/icons/AppIcons.jsx";

// REACT ROUTER DOM IMPORTATION
import { useLocation, useNavigate, useParams } from "react-router-dom";

// URLS IMPORTATIONS
import { urls } from "../../api-urls/api-urls.js";

// GLOBAL FUNCTIONS
import { getTheToken, renderDate, renderFormatTime } from "../../global-functions/globalFunctions.js";

// react translation import
import { useTranslation } from "react-i18next";


// context importation
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx";


import { useFetchServReport } from "../../components/customs/service-report/useFetchServReport.js";




export const ServiceReportsDetailView = ({ grupo }) => {


  // GET react translation
  const [t] = useTranslation("global");

  // GET useNavigate
  const navigate = useNavigate();

  // GET useLocation
  const location = useLocation();

  // GET the language context
  const { lang } = useContext(LangContext);

  // GET params
  const { codRepServ } = useParams();

  // End point
  const EndPoint = `${urls.serviceReport}/${codRepServ}/search?language=${lang}`;

  const { form, state, parts, repState, onHandleCalHor, handleChangeFormVal, handleChangeForm, handleChangeParts } = useFetchServReport(EndPoint, getTheToken().token, lang, codRepServ, "GET");


  // DESTRUCTURING DATA SERVER
  const { serviceReport, employees, employeeRepSer, serFechaReport, HorasTotales } = repState && repState;


  // descargar el reporte de servicio
  const onHandleDownloadServiceReport = async (print = "") => {
    try {
      const response = await fetch(`${urls.serviceReport}?codRepServ=${codRepServ}&action=2&language=${lang}&print=${print}`, {
        headers: {
          "Authorization": getTheToken().token,
          "Accept": "application/pdf"
        }
      });

      if (response.status === 200) {
        const blob = await response.blob();

        const blobURL = URL.createObjectURL(blob);

        // crear enlace oculto
        const a = document.createElement("a");

        a.style.display = "none";
        a.href = blobURL;
        a.download = `service-report-${codRepServ}`;

        document.body.appendChild(a);

        // simular click en el enlace para iniciar la descarga
        a.click();


        // limpiar la url y el enlace de la descargar
        URL.revokeObjectURL(blobURL);
        document.body.removeChild(a);
      }


    } catch (error) {
      console.log(error);
    }
  };


  // useEffect para cargar la data en el estado
  useEffect(() => {
    if ((serviceReport && serviceReport.length > 0) && (HorasTotales && HorasTotales.length > 0)) {

      const totalHoras = HorasTotales[0]?.THorNormal + HorasTotales[0]?.THorExtras + HorasTotales[0]?.THorNocturna + HorasTotales[0]?.THorFeriado + serviceReport[0]?.HorViaje;

      const { Prioridad, Descripcion, SerTubo, SerTransductor, SerTuboR, SerTransductorR, HorViaje } = serviceReport[0]

      const { THorNormal, THorFeriado, THorNocturna, THorExtras } = HorasTotales[0];

      const objUpdate = {
        prioridad: Prioridad.toString(),
        ingInvolucrados: "",
        descripcion: Descripcion,
        serialTuboInst: SerTubo,
        serialTransInst: SerTransductor,
        serialTuboDes: SerTuboR,
        serialTransDes: SerTransductorR,
        horViaje: HorViaje,
        totHoras: totalHoras,
        horas: THorNormal,
        horExtFeriado: THorFeriado,
        horExtNocturna: THorNocturna,
        horExtras: THorExtras
      };


      handleChangeForm(objUpdate);
    }
  }, [serviceReport, HorasTotales]);


  return (
    <>
      {
        state.isLoading && (<LoadingComp />)
      }
      <div className="container display-data">
        <form>
          {/* FILA 1 */}
          <div className="row-flex">
            {/* REPORTE DE SERVICIO TECNICO */}
            <div className="col-5 col-sm-50">
              <strong style={{ color: 'var(--danger-color)' }}>{t("serviceCard.report_serv_tec")}</strong>
            </div>
            <div className="col-4 col-sm-50 fake-input">
              <span>{serviceReport && serviceReport[0]?.NumReporte}</span>
            </div>
            {/* FECHA */}
            <div className="col-5 col-sm-50">
              <strong>{t("serviceCard.date")}</strong>
            </div>
            <div className="flex-auto col-sm-50 fake-input">
              <span>{serviceReport && renderDate(serviceReport[0]?.FecReporte, lang)}</span>
            </div>
          </div>

          {/* FILA 2 */}
          <div className="row-flex">
            {/* CLIENTE */}
            <div className="col-5 col-sm-30">
              <strong>{t("serviceCard.customer")}</strong>
            </div>
            <div className="col-sm-70-mod text-hidden-over">
              <span>{serviceReport && serviceReport[0]?.NomCliente}</span>
            </div>
          </div>

          {/* FLA 3 */}
          <div className="row-flex">
            {/* EQUIPO */}
            <div className="col-5 col-sm-30">
              <strong>{t("serviceCard.equipment")}</strong>
            </div>
            <div className="col-4 col-sm-70-mod">
              <span>{serviceReport && serviceReport[0]?.Equipo}</span>
            </div>
            {/* MODELO */}
            <div className="col-5 col-sm-30">
              <strong>{t("serviceCard.modelo")}</strong>
            </div>
            <div className="flex-auto col-sm-70-mod">
              <span>{serviceReport && serviceReport[0]?.Modelo}</span>
            </div>
          </div>
          <hr className="m-y-small" />

          {/* FILA 4 */}
          <div className="row-flex">
            {/* ESTADO DEL EQUIPO DESPUES DEL SERVICIO */}
            <div className="col-5 col-sm-30">
              <strong>{t("serviceCard.estado_equip")}</strong>
            </div>
            <div className="col-75 col-sm-70-mod row-flex">
              {/* Operativo */}
              <div className="col-4 col-sm-80 row-flex gap-medium">
                <div className="input-control bg-green circle-temp">
                  <input
                    type="radio"
                    value="0"
                    name="temperature"
                    checked={form.prioridad === "0"}
                    onChange={({ target }) => handleChangeFormVal("prioridad", target.value)} />
                </div>
                <div><span>{t("serviceCard.operativo")}</span></div>
              </div>

              {/* Operativo con fallas */}
              <div className="col-3 col-sm-80 row-flex gap-medium">
                <div className="input-control bg-yellow circle-temp">
                  <input
                    type="radio"
                    value="1"
                    name="temperature"
                    checked={form.prioridad === "1"}
                    onChange={({ target }) => handleChangeFormVal("prioridad", target.value)} />
                </div>
                <div><span>{t("serviceCard.operativo_fallas")}</span></div>
              </div>

              {/* Parado */}
              <div className="col-4 col-sm-80 row-flex gap-medium">
                <div className="input-control bg-red circle-temp">
                  <input
                    type="radio"
                    value="4"
                    name="temperature"
                    checked={form.prioridad === "4"}
                    onChange={({ target }) => handleChangeFormVal("prioridad", target.value)} />
                </div>
                <div>
                  <span>{t("serviceCard.equip_detenido")}</span>
                </div>
              </div>
            </div>
          </div>

          {/* FILA 5 */}
          {
            (serviceReport && !serviceReport[0]?.CodEmpGerente) && (
              <div className="row-flex">
                {/* INGENIEROS INVOLUCRADOS */}
                <div className="col-5 col-sm-30">
                  <strong>{t("serviceCard.ing_involucrados")}</strong>
                </div>
                <div className="col-4 col-sm-50">
                  <select
                    name="ingenInvolucrados"
                    value={form.ingInvolucrados}
                    onChange={({ target }) => handleChangeFormVal("ingInvolucrados", target.value)}>
                    <option value=""></option>
                    {
                      employees && employees.map((emp) => (
                        <option key={emp.CodEmpleado} value={emp.CodEmpleado}>{emp.NomEmpleado}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="col-10">
                  <button type="button" className="btn-add-date gap-small row-flex align-c">
                    <Add />
                  </button>
                </div>
              </div>
            )
          }

          {/* FILA para mostrar la lista de los empleados que estan en el reporte */}
          {
            employeeRepSer && employeeRepSer.length > 0 && (
              <div className="row-flex m-y-small d-column">
                {
                  employeeRepSer && employeeRepSer.map((emp) => (
                    <div key={emp.CodEmpleado} className="col-6 col-100-sm">
                      <div className="row-flex gap-small align-c m-l-small">
                        <div>
                          <button type="button" className="btn-delete">
                            <TrashIcon widthP="18" heightP="18" />
                          </button>
                        </div>
                        <div>
                          <span>{emp.NomEmpleado}</span>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            )
          }

          {/* FILA 6 */}
          {
            (serviceReport && !serviceReport[0]?.CodEmpGerente) && (
              <div className="row-flex">
                {/* FECHA DEL SERVICIO (Desde) */}
                <div className="col-5 col-sm-30">
                  <strong>{t("serviceCard.fec_servicio")}</strong>
                </div>
                <div className="col-4 col-sm-50">
                  <input
                    className="input-date-styles"
                    type="datetime-local"
                    name="fecDesde"
                    value={form.fecDesde}
                    onChange={({ target }) => handleChangeFormVal("fecDesde", target.value)} />
                </div>
                {/* Fecha del servicio (Hasta) */}
                <div className="col-5 col-sm-30">
                  <strong>{t("serviceCard.fec_hasta")}</strong>
                </div>
                <div className="col-5 col-sm-50">
                  <input
                    className="input-date-styles"
                    type="datetime-local"
                    name="fecHasta"
                    value={form.fecHasta}
                    onChange={({ target }) => handleChangeFormVal("fecHasta", target.value)} />
                </div>
                <div className="col-7 col-100-sm flex-mob-btn-add-date">
                  <button type="button" className="btn-add-date gap-small row-flex align-c">
                    <Add />
                    {t("serviceCard.add_fec")}
                  </button>
                </div>
              </div>
            )
          }

          <hr className="m-y-small" />

          {/* Tabla para mostrar las fechas */}
          {
            serFechaReport && serFechaReport.length > 0 && (
              <div className="table-container-mobile">
                <table className="table">
                  <thead className="table-head">
                    <tr>
                      <th></th>
                      <th>{t("serviceCard.fec_ini")}</th>
                      <th>{t("serviceCard.fec_fin")}</th>
                      <th>{t("serviceCard.hor_normal")}</th>
                      <th>{t("serviceCard.hor_ext_diurnas")}</th>
                      <th>{t("serviceCard.hor_ext_nocturnas")}</th>
                      <th>{t("serviceCard.hor_ext_feriados")}</th>
                      <th>{t("serviceCard.total_hor")}</th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    {
                      serFechaReport && serFechaReport.map((fec) => (
                        <tr key={fec.CodRepSerFecha}>
                          <td></td>
                          <td>{renderDate(fec.FecInicio, lang)} / {renderFormatTime(fec.FecInicio)}</td>
                          <td>{renderDate(fec.FecFin, lang)} / {renderFormatTime(fec.FecFin)}</td>
                          <td className="txt-center">{fec.HorNormal}</td>
                          <td className="txt-center">{fec.HorExtras}</td>
                          <td className="txt-center">{fec.HorExtNocturna}</td>
                          <td className="txt-center">{fec.HorExtFeriado}</td>
                          <td className="txt-center">{fec.TotalHoras}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            )
          }


          {/* FILA 7 */}
          <div className="row-flex m-y-small">
            {/* DESCRIPCION DEL TRABAJO */}
            <div className="col-5">
              <strong>{t("serviceCard.descript_trab")}</strong>
            </div>
            <div className="col-80">
              <textarea
                className="textarea-description"
                name="descripcionDelTrabajo"
                value={form.descripcion}
                onChange={({ target }) => handleChangeFormVal("descripcion", target.value)}
                placeholder={t("serviceCard.describe")} />
            </div>
          </div>

          {/* FILA 8 */}
          <div className="row-flex">
            {/* NUMERO SERIAL DEL TUBO */}
            <div className="col-5 col-sm-50">
              <strong>{t("serviceCard.num_serial")}</strong>
            </div>
            <div className="col-4 col-sm-50">
              <input
                className="input-text-control"
                name="serialTubo"
                type="text"
                value={form.serialTuboInst}
                onChange={({ target }) => handleChangeFormVal("serialTuboInst", target.value)}
                placeholder={t("serviceCard.serial_tubo_apl")}
                autoComplete="off" />
            </div>
            {/* Numero serial tubo desinstalado */}
            <div className="col-5 col-4 col-sm-50">
              <strong>{t("serviceCard.num_serial_tub_desins")}</strong>
            </div>
            <div className="col-4 col-4 col-sm-50">
              <input
                className="input-text-control"
                type="text"
                name="serTubDes"
                value={form.serialTuboDes}
                onChange={({ target }) => handleChangeFormVal("serialTuboDes", target.value)}
                autoComplete="off" />
            </div>
          </div>

          <div className="row-flex">
            {/* NUMERO SERIAL TRANSDUCTOR instalado */}
            <div className="col-5 col-sm-50">
              <strong>{t("serviceCard.num_serial_trans")}</strong>
            </div>
            <div className="col-4 col-sm-50">
              <input
                className="input-text-control"
                name="serialTransductor"
                type="text"
                value={form.serialTransInst}
                onChange={({ target }) => handleChangeFormVal("serialTransInst", target.value)}
                placeholder={t("serviceCard.num_serial_tran")}
                autoComplete="off" />
            </div>
            {/* Numero serial transductor desinstalado */}
            <div className="col-5 col-sm-50">
              <strong>{t("serviceCard.num_serial_trans_desins")}</strong>
            </div>
            <div className="col-4 col-sm-50">
              <input
                className="input-text-control"
                type="text"
                name="serTransDes"
                value={form.serialTransDes}
                onChange={({ target }) => handleChangeFormVal("serialTransDes", target.value)}
                autoComplete="off" />
            </div>
          </div>

          {/* Tabla para mostrar las partes que se utilizaron en el reporte */}
          {
            process.env.NODE_ENV === "development" && (
              parts && parts.length > 0 && (
                <>
                  <div className="table-container-mobile m-y-medium">
                    <table className="table">
                      <thead className="table-head">
                        <tr>
                          <th>{t("serviceCard.cantidad")}</th>
                          <th>{t("serviceCard.parte")}</th>
                          <th>{t("serviceCard.description")}</th>
                          <th>{t("serviceCard.usado")}</th>
                          <th>{t("serviceCard.no_usa")}</th>
                          <th>{t("serviceCard.cant_pend_just")}</th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {
                          parts && parts.map((part) => (
                            <tr key={`${part.CodSolRepuesto}-${part.CodDetSolRepuesto}`}>
                              <td className="txt-center">{part.Cantidad}</td>
                              <td>{part.NumParte}</td>
                              <td>{part.Descripcion}</td>
                              <td className="col-9">
                                <input
                                  className="input-text-control txt-center"
                                  type="text"
                                  name={`Usad${part.CodSolRepuesto}-${part.CodDetSolRepuesto}`}
                                  value={part.CanUsado ?? 0}
                                  onChange={({ target }) => handleChangeParts(part.CodSolRepuesto, part.CodDetSolRepuesto, target.value, "used")}
                                  autoComplete="off" />
                              </td>
                              <td className="col-9">
                                <input
                                  className="input-text-control txt-center"
                                  type="text"
                                  name={`CanUsad${part.CodSolRepuesto}-${part.CodDetSolRepuesto}`}
                                  value={part.NoUsado ?? 0}
                                  onChange={({ target }) => handleChangeParts(part.CodSolRepuesto, part.CodDetSolRepuesto, target.value)}
                                  autoComplete="off" />
                              </td>
                              <td className="col-9">
                                <input
                                  className="input-text-control txt-center"
                                  type="text"
                                  name={`CanJust${part.CodSolRepuesto}-${part.CodDetSolRepuesto}`}
                                  value={part.CantJust}
                                  readOnly />
                              </td>
                            </tr>
                          )
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="row-flex align-c j-content-center">
                    <button type="submit" className="btn-info">
                      Enviar partes <SendCheckIcon fillP="var(--first-color)" />
                    </button>
                  </div>
                </>
              )
            )
          }

          {/* FILA */}

          <div className="row-flex flex-end">
            <div className="col-6">
              <table className="table">
                <tbody className="t-report-body">
                  <tr>
                    <th className="txt-right">{t("serviceCard.serv_normal")}</th>
                    <td className="col-4">
                      <input
                        className="input-text-control"
                        type="text"
                        name="hours"
                        value={form.horas}
                        readOnly />
                    </td>
                  </tr>
                  <tr>
                    <th className="txt-right">{t("serviceCard.serv_h_ext_diur")}</th>
                    <td className="col-4">
                      <input
                        className="input-text-control"
                        type="text"
                        name="hExtras"
                        value={form.horExtras}
                        readOnly />
                    </td>
                  </tr>
                  <tr>
                    <th className="txt-right">{t("serviceCard.serv_h_ext_noct")}</th>
                    <td>
                      <input
                        className="input-text-control"
                        type="text"
                        name="hExtNoct"
                        value={form.horExtNocturna}
                        readOnly />
                    </td>
                  </tr>
                  <tr>
                    <th className="txt-right">{t("serviceCard.serv_h_saba_dom_feria")}</th>
                    <td>
                      <input
                        className="input-text-control"
                        type="text"
                        name="hExtFeri"
                        value={form.horExtFeriado}
                        readOnly />
                    </td>
                  </tr>
                  <tr>
                    <th className="txt-right">{t("serviceCard.viajes")}</th>
                    <td>
                      <input
                        className="input-text-control"
                        type="text"
                        name="horViaj"
                        value={form.horViaje}
                        onChange={({ target }) => onHandleCalHor(target.value)} />
                    </td>
                  </tr>
                  <tr>
                    <th className="txt-right">{t("serviceCard.total_hor")}</th>
                    <td>
                      <input
                        className="input-text-control wrapper"
                        type="text"
                        name="totalHor"
                        value={form.totHoras}
                        readOnly />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>


          {/* BOTONES DE ACCION */}
          <div className="row-flex j-content-space-around m-y-medium">
            <button className="btn-primary" type="submit">{t("serviceCard.guardar")}</button>
            <button className="btn-success" type="button" onClick={() => onHandleDownloadServiceReport("1")}>Imprimir</button>
            <button className="btn-info" type="button" onClick={() => onHandleDownloadServiceReport("0")}>Solicitar firma</button>
            <button className="btn-secondary" type="reset" onClick={() => navigate(-1, { replace: true })}>{t("navigationMenu.backBtn")}</button>
          </div>
        </form>
      </div>
    </>
  );
};