import React, { useState, useContext } from "react";

// ---->Styles importation
import "../SideBarMenu/SideBarMenu.css";

// ---->Image importation
import logoGlobal from "../../assets/logo-global.png";
import logoGMDH from "../../assets/GMDHC - PNG.png";


// icons importation
import { BankIcon, BoxArrowInLeftIcon, CalendarIcon, CashCoinIcon, ChecksGridIcon, ClipBoardDataIcon, CurrencyExchangeIcon, GearIcon, HomeIcon, PCDisplayIcon, PeopleFillIcon, PersonBadgeIcon, ToolIcon, TranslateIcon, TruckIcon, Warehouse, ITDeparIcon } from "../../components/icons/AppIcons.jsx";

// ----> React Router DOM Importation
import { NavLink, useLocation, useNavigate } from "react-router-dom";

// ---> Importations of useTranslation()
import { useTranslation } from "react-i18next";


// context importation language
import { LangContext } from "../../context/LangContext.jsx";
import { NotificationContext } from "../../context/NotificationContext.jsx";








export const SideBarMenu = ({ handleClick, dataSer }) => {

  // -----> useState() to open the list containers
  const [equipment, setEquipment] = useState(false);
  const [customer, setCustomer] = useState(false);
  const [service, setService] = useState(false);
  const [logistic, setLogistics] = useState(false);
  const [sales, setSales] = useState(false);
  const [travelExpen, setTravelExpen] = useState(false);
  const [hr, setHr] = useState(false);
  const [wareHouse, setWareHouse] = useState(false);
  const [administration, setAdministration] = useState(false);
  const [commercial, setCommercial] = useState(false);
  const [dataGIS, setDataGIS] = useState(false);
  const [itDep, setItDep] = useState(false);


  // language context
  const { handleChangeLang, lang } = useContext(LangContext);

  // notification context
  const { handleNotify } = useContext(NotificationContext);

  // VERSIONADO
  const version = {
    x: "2",
    y: "0",
    z: "0"
  };


  // rutas para los pendientes
  const RouteName = {
    1: "/my-tasks/service",
    3: "/my-tasks/service",
    // 3: "/my-tasks/operation",
    10: "/my-tasks/site-planning",
    12: "/my-tasks/commercial",
  };

  const location = useLocation();

  const navigate = useNavigate();

  const onNavigateHome = () => {
    navigate("/home");
    handleClick();
  };


  // ----> useTranslation() hook importation
  const [t, i18n] = useTranslation("global");


  const onChangeLanguage = (newVal = "") => {

    const FIN_TXT = newVal === "en" ? "The language is English (en)" : "El idioma es Español (es)";

    i18n.changeLanguage(newVal);
    handleChangeLang(newVal);
    localStorage.setItem("language", newVal);
    handleNotify(true, FIN_TXT, false, true);

  };


  // funcion para navegar a pendientes y cerrar el modal del sidebar
  const onNaviPendings = () => {
    navigate(RouteName[dataSer[0]?.CodPerfil] ?? "/home");
    handleClick();
  };

  // funcion para navegar a las reservaciones
  const onNaviReservations = () => {
    navigate("/reservations");
    handleClick();
  };


  // ----> Logout to the App
  const onLogOut = () => {
    localStorage.setItem("lastNav", location.pathname);
    localStorage.removeItem("userInfo");
    navigate("/");
  };


  // GET THE USER INFO string format
  const userInfo = localStorage.getItem("userInfo");

  const userInfoParsed = JSON.parse(userInfo);

  return (
    <aside className="fixed-sidebar" onClick={(event) => event.stopPropagation()}>
      <div className="logo-global-menu">
        <img src={dataSer && (dataSer[0]?.CodEmpresa === "1" ? logoGlobal : logoGMDH)} alt="Logo Global" />
      </div>
      <button
        style={{
          borderRadius: '50%',
          fontWeight: 'bold',
          position: 'absolute',
          left: '100%',
          top: '1rem',
          cursor: 'pointer',
          padding: '0.3rem 0.5rem'
        }}
        onClick={handleClick}>X</button>

      <div className="navbar-container">
        <div className="item-container">
          <button className={`header-button ${location.pathname === '/home' ? 'button-active-class' : ''}`} onClick={onNavigateHome}>
            <HomeIcon />
            {t("navigationMenu.home")}
          </button>
        </div>

        {/* ----> Sesion para mis pendientes <---- */}
        <div className="item-container">
          <button className={`header-button ${location.pathname === "/my-tasks" ? "button-active-class" : ""}`} onClick={onNaviPendings}>
            <ChecksGridIcon />
            {t("navigationMenu.pending")}
          </button>
        </div>
        {
          dataSer[0]?.CodEmpresa === "1" && ( // Mostrar solo para la empresa Global Medica
            <div className="item-container">
              <button className={`header-button ${location.pathname === "/reservations" ? "button-active-class" : ""}`} onClick={onNaviReservations}>
                <CalendarIcon />
                {t("navigationMenu.reserva")}
              </button>
            </div>
          )
        }
        {/* ----> Customers Section <---- */}
        <div className="item-container">
          <button className={`header-button ${location.pathname === '/customers' ? 'button-active-class' : ''}`} onClick={() => setCustomer(!customer)}>
            <PeopleFillIcon />
            {t("navigationMenu.customer")}
          </button>
        </div>
        {
          customer && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/customers/new-customer" onClick={handleClick}>
                    {t("customerView.newCustomer")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/customers" onClick={handleClick}>
                    {t("navigationMenu.customerList")}
                  </NavLink>
                </li>
              </ul>
            </div>
          )
        }
        {/* ----> Another Section <---- */}
        <div className="item-container">
          <button className={`header-button ${location.pathname === '/equipments' ? 'button-active-class' : ''}`} onClick={() => setEquipment(!equipment)}>
            <PCDisplayIcon />
            {t("navigationMenu.equipment")}
          </button>
        </div>
        {
          equipment && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/equipments/create-equipment/equipment" onClick={handleClick}>
                    {t("navigationMenu.newEquipment")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/equipments" onClick={handleClick}>
                    {t("navigationMenu.showEquipments")}
                  </NavLink>
                </li>
              </ul>
            </div>
          )
        }
        {/* ----> Service Section <---- */}
        <div className="item-container">
          <button className={`header-button ${service ? 'button-active-class' : ''}`} onClick={() => setService(!service)}>
            <ToolIcon />
            {t("navigationMenu.service")}
          </button>
        </div>
        {
          service && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/service/scheduling" onClick={handleClick}>{t("navigationMenu.schedule")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/service" onClick={handleClick}>
                    {t("navigationMenu.service")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/new-call" onClick={handleClick}>
                    {t("navigationMenu.newCall")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/service/fmi" onClick={handleClick}>{t("navigationMenu.fmiStb")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/warehouse/technical-approval" onClick={handleClick}>{t("navigationMenu.technicalApproval")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/warehouse/administrative-approval" onClick={handleClick}>{t("navigationMenu.adminApproval")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/service/reports" onClick={handleClick}>
                    {t("navigationMenu.reports")}
                  </NavLink>
                </li>
              </ul>
            </div>
          )
        }
        {/* ----> Logistic Section <----- */}
        <div className="item-container">
          <button className={`header-button ${logistic ? 'button-active-class' : ''}`} onClick={() => setLogistics(!logistic)}>
            <TruckIcon />
            {t("navigationMenu.logistic")}
          </button>
        </div>
        {
          logistic && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/logistic-principal" onClick={handleClick}>
                    {t("navigationMenu.pending")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/logistic-follow-up" onClick={handleClick}>{t("navigationMenu.monitoring")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/supplier-data" onClick={handleClick}>{t("navigationMenu.supplier")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/ship-to" onClick={handleClick}>Ship To</NavLink>
                </li>
                <li><NavLink className="navbar-link" to="/logistic-reports-view" onClick={handleClick}>{t("navigationMenu.reports")}</NavLink></li>
              </ul>
            </div>
          )
        }
        {/* ----> Post Venta Section */}
        <div className="item-container">
          <button className={`header-button ${sales ? 'button-active-class' : ''}`} onClick={() => setSales(!sales)}>
            <CashCoinIcon />
            {t("navigationMenu.sales")}
          </button>
        </div>
        {
          sales && (
            <div className="drop-down-items">
              <ul>
                <li>{t("navigationMenu.pending")}</li>
                <li>
                  <NavLink className="navbar-link" to="/post-sales/proposal" onClick={handleClick}>{t("navigationMenu.proposal")}</NavLink>
                </li>
                <li>{t("navigationMenu.contract")}</li>
                <li>
                  <NavLink className="navbar-link" to="/post-sales/quotations" onClick={handleClick}>{t("navigationMenu.quotation")}</NavLink>
                </li>
                <li>{t("navigationMenu.reports")}</li>
              </ul>
            </div>
          )
        }
        {/* ----> Commercial Section <---- */}
        <div className="item-container">
          <button className={`header-button ${commercial ? 'button-active-class' : ''}`} onClick={() => setCommercial(!commercial)}>
            <GearIcon />
            {t("navigationMenu.commercial")}
          </button>
        </div>
        {
          commercial && (
            <div className="drop-down-items">
              <ul>
                <li><NavLink className="navbar-link" to="/commercial/principal" onClick={handleClick}>{t("navigationMenu.pending")}</NavLink></li>
                <li>
                  <NavLink className="navbar-link" to="/commercial/projects" onClick={handleClick}>{t("navigationMenu.projects")}</NavLink>
                </li>
                <li><NavLink className="navbar-link" to="/commercial/request-orders" onClick={handleClick}>{t("navigationMenu.request")}</NavLink></li>
                <li>Aprobación Director</li>
                <li>
                  <NavLink className="navbar-link" to="/commercial/purchase-order" onClick={handleClick}>{t("navigationMenu.purchaseOrder")}</NavLink>
                </li>
                <li>Seguimiento</li>
                <li>
                  <NavLink className="navbar-link" to="/commercial/service" onClick={handleClick}>{t("navigationMenu.service")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/commercial/service/new-call" onClick={handleClick}>{t("navigationMenu.newCall")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/commercial/reports-view" onClick={handleClick}>{t("navigationMenu.reports")}</NavLink>
                </li>
              </ul>
            </div>
          )
        }

        {/* Viaticos */}
        <div className="item-container">
          <button className={`header-button ${travelExpen ? "button-active-class" : ""}`} onClick={() => setTravelExpen(!travelExpen)}>
            <CurrencyExchangeIcon />
            {t("navigationMenu.viaticos")}
          </button>
        </div>
        {
          travelExpen && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/travel-expenses" onClick={handleClick}>{t("navigationMenu.anticipo")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/travel-expenses/expense-report" onClick={handleClick}>{t("navigationMenu.rela_gastos")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/travel-expenses/approvals" onClick={handleClick}>{t("navigationMenu.aprobacion")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" onClick={handleClick}>{t("navigationMenu.historial")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/administration/payment-expenses" onClick={handleClick}>
                    {t("navigationMenu.payouts")}
                  </NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" onClick={handleClick}>{t("navigationMenu.data")}</NavLink>
                </li>
              </ul>
            </div>
          )
        }

        {/* ----> Human Resources Section <---- */}
        <div className="item-container">
          <button className={`header-button ${hr ? 'button-active-class' : ''}`} onClick={() => setHr(!hr)}>
            <PersonBadgeIcon />
            {t("navigationMenu.humanResource")}
          </button>
        </div>
        {
          hr && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/human-capital/pendings" onClick={handleClick}>{t("navigationMenu.pending")}</NavLink>
                </li>
                {
                  dataSer && [6, 9].includes(dataSer[0]?.CodPerfil) && (
                    <li>
                      <NavLink className="navbar-link" to="/human-capital/employees/new-employee" onClick={handleClick}>
                        {t("navigationMenu.newEmployeeLabel")}
                      </NavLink>
                    </li>
                  )
                }
                <li>
                  <NavLink className="navbar-link" to="/human-capital/employees" onClick={handleClick}>
                    {t("navigationMenu.employees")}
                  </NavLink>
                </li>
                <li>{t("navigationMenu.overTime")}</li>
                <li>
                  <NavLink className="navbar-link" to="/human-capital/vacations/request" onClick={handleClick}>{t("navigationMenu.vacations")}</NavLink>
                </li>
                <li>{t("navigationMenu.trainingAndDevs")}</li>
                <li>{t("navigationMenu.evaluation")}</li>
              </ul>
            </div>
          )
        }
        {/* ----> Ware House Section */}
        <div className="item-container">
          <button className={`header-button ${wareHouse ? 'button-active-Class' : ''}`} onClick={() => setWareHouse(!wareHouse)}>
            <Warehouse />
            {t("navigationMenu.wareHouse")}
          </button>
        </div>
        {
          wareHouse && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/warehouse/principal" onClick={handleClick}>{t("navigationMenu.pending")}</NavLink>
                </li>
                <li>
                  {t("navigationMenu.dispatchRequest")}
                </li>
                <li>
                  {t("navigationMenu.exitApproval")}
                </li>
                <li>
                  {t("navigationMenu.confirmation")}
                </li>
                <li>
                  {t("navigationMenu.parts")}
                </li>
                <li>
                  <NavLink className="navbar-link" to="/warehouse/tools" onClick={handleClick}>{t("navigationMenu.tools")}</NavLink>
                </li>
              </ul>
            </div>
          )
        }
        {/* ----> Administration Section <---- */}
        <div className="item-container">
          <button className={`header-button ${administration ? 'button-active-class' : ''}`} onClick={() => setAdministration(!administration)}>
            <BankIcon />
            {t("navigationMenu.administration")}
          </button>
        </div>
        {
          administration && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/administration/pending" onClick={handleClick}>{t("navigationMenu.pending")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/administration/authorizations" onClick={handleClick}>{t("navigationMenu.autorizaciones")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/administration/consumption-request" onClick={handleClick}>{t("navigationMenu.sol_consumo")}</NavLink>
                </li>
                <li>{t("navigationMenu.expenses")}</li>
                <li>{t("navigationMenu.advance")}</li>
                <li>{t("navigationMenu.billing")}</li>
                <li>{t("navigationMenu.accountPayable")}</li>
                <li>{t("navigationMenu.quotation")}</li>
                <li>
                  <NavLink className="navbar-link" to="/administration/expenses/history" onClick={handleClick}>
                    {t("navigationMenu.history")}
                  </NavLink>
                </li>
                <li>
                  {t("navigationMenu.payouts")}
                </li>
                <li>{t("navigationMenu.reports")}</li>
              </ul>
            </div>
          )
        }
        {/* ----> DATA Section <---- */}
        <div className="item-container">
          <button className={`header-button ${dataGIS ? 'button-active-class' : ''}`} onClick={() => setDataGIS(!dataGIS)}>
            <ClipBoardDataIcon />
            {t("navigationMenu.data")}
          </button>
        </div>
        {
          dataGIS && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/data/division" onClick={handleClick}>{t("navigationMenu.division")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/data/line" onClick={handleClick}>{t("navigationMenu.line")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/data/modality" onClick={handleClick}>{t("navigationMenu.modality")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/data/equipment-type" onClick={handleClick}>{t("navigationMenu.equipType")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/data/model" onClick={handleClick}>{t("navigationMenu.model")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/data/target" onClick={handleClick}>Target</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/data/area" onClick={handleClick}>{t("navigationMenu.zone")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/data/region" onClick={handleClick}>{t("navigationMenu.region")}</NavLink>
                </li>
                <li>
                  <NavLink className="navbar-link" to="/data/city" onClick={handleClick}>{t("navigationMenu.city")}</NavLink>
                </li>
              </ul>
            </div>
          )
        }

        <div className="item-container">
          <button className={`header-button ${itDep ? 'button-active-class' : ''}`} onClick={() => setItDep(!itDep)}>
            <ITDeparIcon />
            IT
          </button>
        </div>

        {
          itDep && (
            <div className="drop-down-items">
              <ul>
                <li>
                  <NavLink className="navbar-link" to="/request-it" onClick={handleClick}>Solicitudes</NavLink>
                </li>
              </ul>
            </div>
          )
        }

        {/* Aquí van los mensajes de notificaciones */}

      </div>

      {/* Configuraciones */}
      <div className="config-side-bar">
        <h5 className="h5-config-sect">Configuraciones</h5>
        <hr />
        <div className="user-detail">
          <h6 style={{ color: 'var(--first-color)' }}>{userInfoParsed.name}</h6>
          <span style={{ color: 'var(--first-color)' }}>{userInfoParsed.email}</span>
        </div>
        {/* Cambio de idioma */}
        <div style={{
          marginBottom: '0.3rem'
        }} className="language-sect">
          <TranslateIcon />
          <select
            style={{ border: 'none' }}
            name="lang"
            className="select-lang"
            value={lang}
            onChange={({ target }) => onChangeLanguage(target.value)}>
            <option value="es">Español (es)</option>
            <option value="en">English (en)</option>
          </select>
        </div>
        {/* Logout button */}
        <hr />
        {/* Logout Button */}
        <div className="btn-logout-sect">
          <button className="btn-logout" onClick={onLogOut}>
            <BoxArrowInLeftIcon />
            {t("navigationMenu.logout")}
          </button>
        </div>

        {/* VERISIONADO */}
        <div className="version-container">
          <strong className="version-text">V</strong>
          <strong className="version">{version.x}.{version.y}.{version.z}</strong>
        </div>



      </div>
    </aside>
  );
};

