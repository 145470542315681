// listar las solicitudes de TI





export const RequestItList = () => {

  return (
    <div className="container display-data">
      <h1>Listado</h1>
    </div>
  );
};