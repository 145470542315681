// componente para cargar las cotizaciones de comercial en las llamadas

import { useState, useContext } from "react";

import { useTranslation } from "react-i18next";


// context notification
import { NotificationContext } from "../../context/NotificationContext.jsx";
import { urls } from "../../api-urls/api-urls.js";





export const UploadServQuot = ({ onNotify, lang = "", token = "", Id = "" }) => {

  // state definition
  const [doc, setDoc] = useState(null); // estado para cargar los reportes
  const [form, setForm] = useState("");


  // context notification
  const { handleNotify } = useContext(NotificationContext);


  // react translation
  const [t] = useTranslation("global");


  // funcion para cargar el archivo
  const handleFileChange = ({ target }) => {
    const file = target.files[0];
    setDoc(file);
  };


  // funcion para subir el formulario al servidor
  const handleUploadDoc = async (event) => {
    event.preventDefault();

    try {

      if (doc) {

        const formData = new FormData();

        formData.append("file", doc);
        formData.append("name", form);

        // se envia el archivo al servidor
        const response = await fetch(`${urls.calls}/${Id}/upload-quot?language=${lang}`, {
          method: "POST",
          headers: {
            "Authorization": token
          },
          body: formData
        });

        const data = await response.json();

        if (response.status === 200) {
          setDoc(null);
          onNotify("reload", true);
          onNotify("modalCot", false);
          handleNotify(true, data?.msg, false, true);
        } else if (404) {
          handleNotify(true, data?.msg, true);
        }

      } else {
        handleNotify(true, t("serviceCard.no_archivo"), true);
      }

    } catch (errr) {
      console.log(errr);
    }
  };



  return (
    <div className="background-modal">
      <div className="container-modal-upload-docs">
        <form onSubmit={handleUploadDoc}>
          <div className="row-flex">
            <div className="col-6">
              <strong>{t("serviceCard.doc_subir")}</strong>
            </div>
            <div className="col-6">
              <input
                type="file"
                name="file"
                accept=".pdf"
                onChange={(event) => handleFileChange(event)} />
            </div>
          </div>
          <div className="row-flex">
            <div className="col-6">
              <strong>{t("TableParts.descrip")}</strong>
            </div>
            <div className="col-6">
              <input
                className="input-text-control"
                type="text"
                name="descript"
                value={form}
                onChange={({ target }) => setForm(target.value)} />
            </div>
          </div>
          <div>
            <span className="span txt-muted">{t("serviceCard.formato")}</span>
          </div>
          <div className="row-flex j-content-space-around m-y-medium">
            <button type="submit" className="btn-primary">{t("serviceCard.subir")}</button>
            <button type="reset" className="btn-secondary" onClick={() => onNotify("modalCot", false)}>{t("LogisticViewComp.cerrar")}</button>
          </div>
        </form>
      </div>
    </div>
  );
};