// componente para la reservacion del salon de conferencias

import { useContext } from "react";


// components importation
import { CreateEvent } from "../../components/reservations/CreateEvent.jsx";


import { useFetchReserva } from "../../components/customs/reservations/useFetchReserva.js";
import { getTheToken } from "../../global-functions/globalFunctions.js";


// language context
import { LangContext } from "../../context/LangContext.jsx";





export const ReservationsView = () => {

  // End point
  // const EndPoint = `${urls.roomReserv}?action=0`;

  const { lang } = useContext(LangContext);

  const { currentDate, dataState, form, arrColor, handleNextWeek, handlePreviousWeek, handleReloadData, handleSelectColor, handleChangeMultiForm, renderDays, renderTimeSlots, getStartOfWeek, handleChangeFormVal } = useFetchReserva("", getTheToken().token, lang);

  const { rooms } = dataState && dataState;


  return (
    <div className="container display-data">
      <header style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', padding: '10px' }}>
        <button className="btn-pre-nex" onClick={handlePreviousWeek}>Anterior</button>
        <h2>Semana de {getStartOfWeek(currentDate).toLocaleDateString('es-ES')}</h2>
        <button className="btn-pre-nex" onClick={handleNextWeek}>Siguiente</button>
      </header>

      <div className="row-flex m-y-small">
        <div className="col-4">
          <strong>Ver actividad para el Salón</strong>
        </div>
        <div className="col-7">
          <select
            name="room"
            value={form.location}
            onChange={({ target }) => handleChangeFormVal("location", target.value)}>
            {
              rooms && rooms.map((ro) => (
                <option key={ro.CodSala} value={ro.CodSala}>{ro.Nombre}</option>
              ))
            }
          </select>
        </div>
      </div>

      <div style={{ display: 'grid', gridTemplateColumns: '100px repeat(7, 1fr)' }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: "flex-end" }}>
          {/* <div style={{ padding: "7px", borderBottom: "1px solid #ccc", textAlign: "center" }}>
          </div> */}
          {renderTimeSlots(true)}
        </div>
        {renderDays()}
      </div>

      {
        form.showModal && (
          <CreateEvent lang={lang} form={form} onChangeVal={handleChangeFormVal} handleSelectColor={handleSelectColor} arrColor={arrColor} handleChangeMultiForm={handleChangeMultiForm} onReload={handleReloadData} />
        )
      }
    </div>
  );
};
