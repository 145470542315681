import React, { useEffect, useState, useContext } from "react";


// react translation import
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { urls } from "../../api-urls/api-urls.js";


// context import
import { LangContext } from ".././../context/LangContext.jsx";
import { NotificationContext } from ".././../context/NotificationContext.jsx";



export const ModalFilterLogisticReports = ({ onClose, onSetNewState, token }) => {

  // GET the context language importation
  const { lang } = useContext(LangContext);

  // GET the context notification importation
  const { handleNotify } = useContext(NotificationContext);

  const [t] = useTranslation("global");


  // GET the useNavigate
  const navigate = useNavigate();

  // useSearchParams
  const [params, setParams] = useSearchParams();

  // STATE DEFINITION
  const [dataServer, setDataServer] = useState([]); // almacenar la data que viene del servidor
  const [form, setForm] = useState({ division: params.get("nom_divi") ?? "", proveedor: params.get("nom_suppl") ?? "", cliente: params.get("nom_cli") ?? "", linea: params.get("nom_lin") ?? "", modalidad: params.get("nom_moda") ?? "", tipEquip: params.get("tip_equip") ?? "", modelo: params.get("nom_model") ?? "", zona: params.get("nom_area") ?? "", fecDesde: params.get("from") ?? "", fecHasta: params.get("to") ?? "", etapa: params.get("nom_etap") ?? "", company: params.get("nom_empr") ?? "", nroParte: params.get("num_part") ?? "", workFlow: params.get("workflow") ?? "", reporte: params.get("num_report") ?? "", tipSol: params.get("tip_sol") ?? "" });
  const [controller, setController] = useState(null); // controlador para cancelar la peticion al servidor
  const [state, setState] = useState({ searching: false, isLoading: false });

  // DESTRUCTURING DATA SERVER
  const { division, line, modality, tipEquipment, equipmentModel, company, zone, reportes, empPerm, proveedor, etapas } = dataServer && dataServer;

  const PAGE_SIZE = 500;


  // funcion para buscar en el servidor
  const onHandleFilter = async (event) => {
    event.preventDefault();

    const END_POINT = `${urls.logisticReports}?language=${lang}&page=1&pageSize=${PAGE_SIZE}&company=${form.company}&zone=${form.zona}&report=${form.reporte}&division=${form.division}&nom_supp=${form.proveedor}&nom_cust=${form.cliente}&line=${form.linea}&modality=${form.modalidad}&tip_equp=${form.tipEquip}&model=${form.modelo}&from=${form.fecDesde}&to=${form.fecHasta}&workflow=${form.workFlow}&nro_part=${form.nroParte}&nom_etap=${form.etapa}&tip_sol=${form.tipSol}`;

    try {
      setState((val) => ({ ...val, searching: true }));
      const response = await fetch(END_POINT, {
        method: "POST",
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        }
      });

      const data = await response.json();

      if (response.status === 200) {
        let page = 1;
        let moreD = true;
        if (data?.reporte.length === PAGE_SIZE) {
          page = page + 1;
        }

        if (page > 1) {
          moreD = false;
        }
        onSetNewState(data?.reporte, page, data?.Total, "", data?.Title, moreD, data?.Reporte);
        onClose(); // cerrar el modal
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 404) {
        onSetNewState([], 1, 0, data?.msg, "", false, data?.Reporte);
        handleNotify(true, data?.msg, true);
      }

      setState((val) => ({ ...val, searching: false }));

    } catch (error) {
      console.log(error);
    }
  };



  // funcion para traer la data del servidor
  const fetchDataServer = async () => {
    const abortController = new AbortController();

    const END_POINT = `${urls.logisticReports}?language=${lang}`;

    setController(abortController);

    try {
      setState((val) => ({ ...val, isLoading: true }));
      const response = await fetch(END_POINT, {
        headers: {
          "Authorization": token,
          "Content-Type": "application/json"
        },
        signal: abortController.signal
      });

      const data = await response.json();

      if (response.status === 200) {
        setDataServer(data);
        setState((val) => ({ ...val, dataVisible: true }));
      } else if (response.status === 401) {
        localStorage.removeItem("userInfo");
        return navigate("/");
      } else if (response.status === 403) {
        setState((val) => ({ ...val, unauthorized: true, dataVisible: false }));
      } else if (response.status === 500) {
        // error interno en el servidor
      }

      setState((val) => ({ ...val, isLoading: false }));

    } catch (error) {
      console.log(error);
    }
  };


  // useEffect Declaration
  useEffect(() => {

    fetchDataServer();

    return () => {
      if (controller) {
        controller.abort();
      }
    };

  }, []);



  // funcion para cambiar los valores
  const onHandleChangeVal = (newVal = "", name = "", queryPar = "") => {

    setForm((val) => ({ ...val, [name]: newVal }));

    params.set("filt", 1);
    params.set(queryPar, newVal);
    setParams(params);
  };



  // useEffect para cargar la data del usuario a la empresa y zona que pertenece
  useEffect(() => {
    if (empPerm && params.get("filt") === null) {
      onHandleChangeVal(empPerm[0]?.CodEmpresa, "company", "nom_empr");
      onHandleChangeVal(empPerm[0]?.CodZona, "zona", "nom_area");
    }
  }, [empPerm]);



  return (
    <div className="background-modal">
      <form onSubmit={onHandleFilter} className="container-select-customer">
        <div className="modal-header">
          <h4>Filtro</h4>
          <button type="button" className="btn-close" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </button>
        </div>
        <div className="modal-body">

          {
            state.isLoading && (<strong>{t("LoginView.cargando")}</strong>)
          }

          {
            !state.isLoading && (
              <>
                <div className="row-flex">
                  <div className="col-7">
                    <strong>Reporte</strong>
                  </div>
                  <div className="col-80 flex-grow">
                    <select
                      name="report"
                      value={form.reporte}
                      onChange={({ target }) => onHandleChangeVal(target.value, "reporte", "num_report")}>
                      <option value=""></option>
                      {
                        reportes && reportes.map((re) => (
                          <option key={re.id} value={re.id}>{re.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>División</strong>
                  </div>
                  <div className="col-5">
                    <select
                      name="division"
                      value={form.division}
                      onChange={({ target }) => onHandleChangeVal(target.value, "division", "nom_divi")}>
                      <option value=""></option>
                      {
                        division && division.map((divi) => (
                          <option key={divi.CodDivision} value={divi.CodDivision}>{divi.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-8">
                    <strong>Línea</strong>
                  </div>
                  <div className="col-5">
                    <select
                      name="line"
                      disabled={form.division === ""}
                      value={form.linea}
                      onChange={({ target }) => onHandleChangeVal(target.value, "linea", "nom_lin")}>
                      <option value=""></option>
                      {
                        line && line.filter((filtLin) => filtLin.CodDivision === form.division).map((lin) => (
                          <option key={lin.CodLinea} value={lin.CodLinea}>{lin.NomLinea}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-8">
                    <strong>Modalidad</strong>
                  </div>
                  <div className="col-8 flex-grow">
                    <select
                      name="modal"
                      disabled={form.linea === ""}
                      value={form.modalidad}
                      onChange={({ target }) => onHandleChangeVal(target.value, "modalidad", "nom_moda")}>
                      <option value=""></option>
                      {
                        modality && modality.filter((filtMod) => filtMod.CodLinea === form.linea).map((moda) => (
                          <option key={moda.CodModalidad} value={moda.CodModalidad}>{moda.NomModalidad}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Tipo de equipo</strong>
                  </div>
                  <div className="col-32-5">
                    <select
                      name="tipEqup"
                      disabled={form.modalidad === ""}
                      value={form.tipEquip}
                      onChange={({ target }) => onHandleChangeVal(target.value, "tipEquip", "tip_equip")}>
                      <option value=""></option>
                      {
                        tipEquipment && tipEquipment.filter((filtTipEq) => filtTipEq.CodModalidad === form.modalidad).map((tipEqup) => (
                          <option key={tipEqup.CodTipEquipo} value={tipEqup.CodTipEquipo}>{tipEqup.NomTipEqup}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-5">
                    <strong>Modelo</strong>
                  </div>
                  <div className="col-4 flex-grow">
                    <select
                      name="model"
                      disabled={form.tipEquip === ""}
                      value={form.modelo}
                      onChange={({ target }) => onHandleChangeVal(target.value, "modelo", "nom_model")}>
                      <option value=""></option>
                      {
                        equipmentModel && equipmentModel.filter((modFil) => modFil.CodTipEquipo.toString() === form.tipEquip).map((model) => (
                          <option key={model.CodModelo} value={model.CodModelo}>{model.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Empresa</strong>
                  </div>
                  <div className="col-32-5">
                    <select
                      name="company"
                      value={form.company}
                      onChange={({ target }) => onHandleChangeVal(target.value, "company", "nom_empr")}>
                      <option value=""></option>
                      {
                        company && company.map((emp) => (
                          <option key={emp.CodEmpresa} value={emp.CodEmpresa}>{emp.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                  <div className="col-5">
                    <strong>Zona</strong>
                  </div>
                  <div className="col-5 flex-grow">
                    <select
                      name="area"
                      value={form.zona}
                      onChange={({ target }) => onHandleChangeVal(target.value, "zona", "nom_area")}>
                      <option value=""></option>
                      {
                        zone && zone.filter((filtZo) => filtZo.CodEmpresa.toString() === form.company).map((zon) => (
                          <option key={zon.CodZona} value={zon.CodZona}>{zon.NomZona}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Cliente</strong>
                  </div>
                  <div className="col-5 flex-grow">
                    <input
                      className="input-text-control"
                      type="text"
                      name="customer"
                      value={form.cliente}
                      onChange={({ target }) => onHandleChangeVal(target.value, "cliente", "nom_cli")}
                      autoComplete="off" />
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Desde</strong>
                  </div>
                  <div className="col-5">
                    <input
                      className="input-date-styles"
                      type="date"
                      name="from"
                      value={form.fecDesde}
                      onChange={({ target }) => onHandleChangeVal(target.value, "fecDesde", "from")} />
                  </div>
                  <div className="col-8">
                    <strong>Hasta</strong>
                  </div>
                  <div className="col-5">
                    <input
                      className="input-date-styles"
                      type="date"
                      name="to"
                      value={form.fecHasta}
                      onChange={({ target }) => onHandleChangeVal(target.value, "fecHasta", "to")} />
                  </div>
                  <div className="col-8">
                    <strong>WorkFlow</strong>
                  </div>
                  <div className="col-5 flex-grow">
                    <input
                      className="input-text-control"
                      type="text"
                      name="workflow"
                      value={form.workFlow}
                      onChange={({ target }) => onHandleChangeVal(target.value, "workFlow", "workflow")} />
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Proveedor</strong>
                  </div>
                  <div className="col-80 flex-grow">
                    <select
                      name="supplier"
                      value={form.proveedor}
                      onChange={({ target }) => onHandleChangeVal(target.value, "proveedor", "nom_suppl")}>
                      <option value=""></option>
                      {
                        proveedor && proveedor.map((pro) => (
                          <option key={pro.CodProveedor} value={pro.CodProveedor}>{pro.Nombre}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                <div className="row-flex">
                  <div className="col-7">
                    <strong>Nro. Parte</strong>
                  </div>
                  <div className="col-32-5">
                    <input
                      className="input-text-control"
                      type="text"
                      name="nroPart"
                      value={form.nroParte}
                      onChange={({ target }) => onHandleChangeVal(target.value, "nroParte", "num_part")} />
                  </div>
                  {
                    form.reporte === "20" && (
                      <>
                        <div className="col-5 fade-animation">
                          <strong>Tipo de solicitud</strong>
                        </div>
                        <div className="col-5 flex-grow">
                          <select
                            name="tipSol"
                            value={form.tipSol}
                            onChange={({ target }) => onHandleChangeVal(target.value, "tipSol", "tip_sol")}>
                            <option value=""></option>
                            <option value="0">Todo</option>
                            <option value="2">{t("TipSol.contra_perfo")}</option>
                            <option value="5">{t("TipSol.ord_compr")}</option>
                            <option value="1">{t("TipSol.garan")}</option>
                          </select>
                        </div>
                      </>
                    )
                  }
                  {
                    form.reporte === "10" && (
                      <>
                        <div className="col-5 fade-animation">
                          <strong>Etapa</strong>
                        </div>
                        <div className="col-5 flex-grow">
                          <select
                            name="step"
                            value={form.etapa}
                            onChange={({ target }) => onHandleChangeVal(target.value, "etapa", "nom_etap")}>
                            <option value=""></option>
                            {
                              etapas && etapas.map((eta) => (
                                <option key={eta.IdEtapa} value={eta.IdEtapa}>{eta.NomEtapa}</option>
                              ))
                            }
                            <option value="0">--Todas--</option>
                          </select>
                        </div>
                      </>
                    )
                  }
                </div>
              </>
            )
          }

        </div>
        <div className="modal-footer j-content-space-around">
          <button type="submit" className="btn-success" disabled={state.searching}>{state.searching ? t("PartModify.buscando") : t("PartModify.buscar")}</button>
          <button type="reset" className="btn-secondary" onClick={onClose}>Cerrar</button>
        </div>
      </form>
    </div>
  );
};